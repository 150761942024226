.slider-pagination {
  &_white {
    position: absolute;
    z-index: 100;
    display: flex;

    .swiper-pagination-bullet {
      width: 39px;
      height: 3px;
      background: var(--white50);
      display: inline-block;
      transition: background var(--default-timing) var(--default-transition-function);

      &:not(:first-child) {
        margin-left: 2px;
      }

      &.swiper-pagination-bullet-active {
        background: white;
      }
    }
  }
}

@include respond-up('s-large') {
  .slider-pagination {
    &_white {
      left: 50%;
      transform: translateX(-50%);
      bottom: 80px;
    }
  }
}

@include respond('medium') {
  .slider-pagination {
    &_white {
      right: 0;
      bottom: 83px;
    }
  }
}

@include respond-down('small') {
  .slider-pagination {
    &_white {
      right: 0;
      bottom: 109px;
    }

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -20px;
    z-index: 100;
    display: flex;

    .swiper-pagination-bullet {
      width: 23px;
      height: 3px;
      background: var(--grey-line);
      display: inline-block;
      transition: background var(--default-timing) var(--default-transition-function);

      &:not(:first-child) {
        margin-left: 2px;
      }

      &.swiper-pagination-bullet-active {
        background: var(--black-text);
      }
    }
  }
}