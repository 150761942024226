.event-slider {
  &__picture {
    display: flex;
    width: 100%;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  &__slider {
    &.swiper {
      margin: 0 calc(0px - var(--grid-spacer-and-indent));
      padding-right: calc(var(--grid-spacer-and-indent) * 2);
    }
  }

  &__slider-list {
    padding-left: var(--grid-spacer-and-indent);
  }

  &__slider-item {
    &:not(:first-child) {
      margin-left: var(--grid-gap);
    }
  }
}

@include respond-up('s-large') {
  .event-slider {
    &__title {
      line-height: 130%;
    }

    &__top {
      display: flex;
      justify-content: space-between;
    }

    &__slider-wrap {
      margin-top: 27px;
    }

    &__slider-item {
      &.swiper-slide {
        width: var(--grid-column6);
      }
    }

    &__picture {
      height: calc(var(--grid-column6) * 410/669);
    }
  }
}

@include respond('medium') {
  .event-slider {
    &__slider-wrap {
      margin-top: 25px;
    }

    &__slider-item {
      &.swiper-slide {
        width: var(--grid-column5);
      }
    }

    &__picture {
      height: calc(var(--grid-column5) * 391/581);
    }
  }
}

@include respond-down('medium') {
  .event-slider {
    &__nav-wrap {
      display: none;
    }
  }
}

@include respond-down('small') {
  .event-slider {
    &__slider-wrap {
      margin-top: 18px;
    }

    &__slider-item {
      &.swiper-slide {
        width: calc(var(--grid-column4) - 18px);
      }
    }

    &__picture {
      height: calc((var(--grid-column4) - 18px) * 196/320);
    }
  }
}