.weapons {
  &__weapon-type{
    &.micro-title{
      &:before{
        background: var(--bvi-text-color);
      }
    }
  }
}

@include respond-up('large') {
  .weapons {

  }
}

@include respond-up('medium') {
  .weapons {

  }
}

@include respond('medium') {
  .weapons {

  }
}

@include respond-down('medium') {
  .weapons {

  }
}

@include respond-down('small') {
  .weapons {

  }
}