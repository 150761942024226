.modal-form {
  &__submit {
    width: 100%;
  }

  &__title,
  &__subtitle {
    display: flex;
    justify-content: center;
  }

  &__policy-link {
    padding: 5px;
    margin: -5px;
  }
}

@include respond-up('s-large') {
  .modal-form {

    &__form {
      &_recall {
        margin-top: 50px;
      }
    }

    &__subtitle {
      margin-top: 18px;
    }

    &__submit {
      margin-top: 20px;
    }
  }
}

@include respond-up('medium') {
  .modal-form {
    &__checkbox-wrap {
      margin-top: 35px;
    }
  }
}

@include respond('medium') {
  .modal-form {
    &__field {
      &:not(:first-child) {
        margin-top: 45px;
      }
    }

    &__form {
      &_recall {
        margin-top: 27px;
      }
    }

    &__subtitle {
      margin-top: 15px;
      text-align: center;
    }

    &__submit {
      margin-top: 15px;
    }
  }
}

@include respond-down('small') {
  .modal-form {
    &__field {
      &:not(:first-child) {
        margin-top: 40px;
      }
    }

    &__form {
      &_recall {
        margin-top: 30px;
      }
    }

    &__subtitle {
      margin-top: 12px;
      text-align: center;
    }

    &__checkbox-wrap {
      margin-top: 30px;
    }

    &__submit {
      margin-top: 15px;
    }
  }
}