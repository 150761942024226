.requisites {
  position: relative;
   &:before {
     content: '';
     position: absolute;
     width: 100vw;
     height: 1px;
     background: var(--white12);
     top: 0;
     left: calc(0px - var(--grid-spacer-and-indent));
   }
  &__title {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    text-transform: uppercase;
    font-size: 14px;
  }

  &__item {
    &_number-wrap-first {
      display: flex;
    }
  }
}

@include respond-up('large') {
  .requisites {
    &__list {
      width: var(--grid-column7);
    }

    &__item {
      width: var(--grid-column3);
    }
  }
}

@include respond-up('s-large') {
  .requisites {
    padding-top: 50px;
    margin-top: 60px;
  }
}

@include respond('s-large') {
  .requisites {
    &__list {
      width: var(--grid-column8);
    }

    &__item {
      width: 100%;
    }
  }
}

@include respond-up('medium') {
  .requisites {
    &__list {
      margin-top: 22px;
      display: grid;
      grid-row-gap: 13px;
      grid-column-gap: calc(var(--grid-column) + (var(--grid-gap) * 2));
      grid-template-areas:
        "name first-number-wrap"
        "address second-number-wrap";
    }

    &__number-second {
      margin-left: 30px;
    }

    &__item {
      &_name {
        grid-area: name;
      }

      &_address {
        grid-area: address;
      }

      &_number-wrap-first {
        grid-area: first-number-wrap;
      }

      &_number-wrap-second {
        grid-area: second-number-wrap;
      }
    }
  }
}

@include respond('medium') {
  .requisites {
    padding-top: 40px;
    margin-top: 50px;
  }
}

@include respond-down('medium') {
  .requisites {

  }
}

@include respond-down('small') {
  .requisites {
    padding-top: 30px;
    margin-top: 40px;

    &__list {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
    }

    &__item {
      &:not(:first-child) {
        margin-top: 12px;
      }
    }

    &__number-second {
      margin-left: 38px;
    }
  }
}