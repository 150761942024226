.error-page {
  display: flex;

  &__text-wrap {
    display: flex;
    flex-direction: column;
  }
}

@include respond-up('large') {
  .error-page {
    &__code-wrap {
      transform: translateX(-73px);
    }
  }
}

@include respond-up('s-large') {
  .error-page {
    padding-top: 113px;
    padding-bottom: 188px;
    align-items: center;

    &__button {
      margin-top: 40px;
    }

    &__description {
      margin-top: 20px;
      width: var(--grid-column4);
    }
  }
}

@include respond-up('medium') {
  .error-page {
    justify-content: space-between;
  }
}

@include respond('medium') {
  .error-page {
    padding-top: 80px;
    padding-bottom: 200px;
    align-items: flex-start;

    &__button {
      margin-top: 35px;
    }

    &__description {
      margin-top: 18px;
      width: calc(var(--grid-column2) + var(--grid-gap) + (var(--grid-column) / 2));
    }

    &__code-wrap {
      transform: translateY(12px);
      svg {
        width: 322px;
        height: auto;
      }
    }
  }
}

@include respond-down('small') {
  .error-page {
    padding-top: 38px;
    padding-bottom: 50px;
    flex-direction: column;

    &__button {
      margin-top: 25px;
    }

    &__description {
      margin-top: 15px;
      width: var(--grid-column4);
    }

    &__code-wrap {
      margin-top: 50px;
      align-self: center;
      svg {
        width: 262px;
        height: auto;
      }
    }
  }
}