.header {

}

@include respond-up('s-large') {
  .header {
    &__nav-children-list-wrap{
      &:before{
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: -10px;
        width: 100%;
        height: 10px;
        pointer-events: auto;
      }
    }

    &__nav-link{
      svg{
        background-color: var(--bvi-text-color)!important;
        path{
          fill: var(--bvi-text-color) !important;
          stroke: var(--bvi-text-color)!important;
        }
      }
    }

    &__nav-children-list-wrap{
      border: 1px solid var(--bvi-bg-color);
    }

    &__nav-child{
      &:hover{
        text-decoration: underline;
      }
    }

    &__nav-children-list-wrap{
      top: calc(100% +  10px);
    }

    &__part{
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &__container{
      padding-top: 10px;
    }

    &__wrap{
      height: auto;
      flex-direction: column;
      row-gap: 10px;

      &_lower {
        align-items: flex-start;
      }

      &:before{
        z-index: 1;
      }
    }

    &__external-link{
      padding: 10px 8px;
    }

    &__container-wrap {
      &:before {
        z-index: 100;
      }
    }

    &__phone-wrap{
      margin-left: unset;
    }

    &__nav-link{
      padding: 10px clamp(6px, calc(var(--vw, 1vw)* 15 / 16), 15px) 10px;
    }

    &__nav-list {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    &__wrap_lower {
      height: auto;
    }
  }

  .bvi-body[data-bvi-theme=white],
  .bvi-body[data-bvi-theme=black],
  .bvi-body[data-bvi-theme=blue],
  .bvi-body[data-bvi-theme=brown],
  .bvi-body[data-bvi-theme=green] {
    .header__nav-link {
      svg {
        path {
          fill: var(--bvi-bg-color)!important;
        }
      }
      &:hover{
        svg{
          background-color: var(--bvi-text-color)!important;
          path{
            fill: var(--bvi-text-color) !important;
            stroke: var(--bvi-bg-color)!important;
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .header {

  }
}

@include respond('medium') {
  .header {

  }
}

@include respond-down('medium') {
  .header {

  }
}

@include respond-down('small') {
  .header {

  }
}