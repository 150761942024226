.label {
  padding: 9px 15px;
  color: white;
  display: inline-block;
  pointer-events: none;
  font-family: var(--font);
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 4px;

  &_red {
    background: var(--primary-red);
  }

  &_blue {
    background: var(--primary-blue);
  }
}