.tabs {
  &__link {
    &::before {
      background: var(--bvi-text-color);
    }
  }
}

@include respond-down('medium') {
  .tabs {
    margin-top: 0;

    &__list {
      flex-wrap: wrap;
    }

    &__item {
      margin: 0;

      &:first-child,
      &:last-child {
        .tabs {
          &__link {
            margin: 0;
          }
        }
      }
    }

    &__link {
      margin: 0;
    }
  }
}

@include respond-down('small') {
  .tabs {
    &__text {
      overflow-wrap: break-word;
      word-break: break-word;
      white-space: normal;
    }
  }
}