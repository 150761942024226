.events {
  &__item {
    &._invisible {
      display: none;
    }
  }

  &__link {
    position: relative;
    display: flex;
    height: 100%;

    &_vertical {
      .events {
        &__wrap {
          flex-direction: column-reverse;
        }

        &__picture {
          border-radius: 0 0 4px 4px;
        }
      }

      &.events__link_has-image {
        .events__image-wrap {
          position: relative;
        }
      }
    }
  }

  &__arrow {
    position: absolute;
    z-index: 100;
  }

  &__date-wrap {
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
    border-top: 1px solid var(--grey-line);
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__info-wrap {
    background: white;
    padding: var(--padding-top) 25px 20px 25px;
    border-radius: 0 0 4px 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0 auto;
  }

  &__picture {
    display: flex;
    width: 100%;
    overflow: hidden;
    border-radius: 4px 4px 0 0;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__time {
    margin-left: 16px;
    padding-left: 28px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 12px;
      height: 1px;
      background: var(--grey-text);
    }
  }

  &__wrap {
    &._wide {
      .events {
        &__date-wrap {
          margin-top: 0;
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .events {
    padding-top: 70px;
    padding-bottom: 120px;

    &__date-wrap {
      margin-top: 70px;
    }

    &__picture {
      height: calc(var(--grid-column4) * 267 / 439);
      width: var(--grid-column4);
    }

    &__date-gray-wrap {
      padding-top: 5px;
      margin-left: 34px;
    }

    &__name {
      transition: color var(--default-timing) var(--default-transition-function);
    }

    &__image {
      //transition: transform var(--default-timing) var(--default-transition-function);
      transition: transform 1s cubic-bezier(0.420, 0.000, 0.002, 1.000);
    }

    &__link {
      &:hover {
        .events {
          &__name {
            color: var(--primary-red);
          }

          &__image {
            //transform: scale(1.12);
            transform: scale3d(1.06, 1.06, 1);
          }

          &__arrow {
            transform: scale(1.15);
          }
        }
      }

      &._wide {
        padding: 20px;

        .events {
          &__picture {
            height: calc(var(--grid-column4) * 267 / 439);
            width: var(--grid-column4);
          }

          &__arrow {
            top: 40px;
            right: 60px;
          }

          &__info-wrap {
            padding: 20px 60px;
            margin-left: 20px;
            min-height: 100%;

            &:before {
              bottom: -20px;
              height: calc(100% + 40px);
            }
          }

          &__name {
            max-width: calc(var(--grid-column4) + var(--grid-gap));
          }
        }
      }
    }

    &__list {
      grid-row-gap: 50px;
    }

    &__item {
      grid-column: span 4;
    }

    &__info-wrap {
      --padding-top: 26px;
    }

    &__arrow {
      top: 20px;
      right: 25px;
    }
  }
}

@include respond-up('medium') {
  .events {
    &__link {
      &._wide {
        display: flex;
        background: white;
        border-radius: 4px;

        .events {
          &__date-wrap {
            border: none;
          }

          &__info-wrap {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              background: var(--grey-line);
              left: 0;
              width: 1px;
            }
          }

          &__picture {
            border-radius: 4px;
          }
        }
      }
    }

    &__wrap {
      &._wide {
        flex-direction: row;
      }
    }
  }
}

@include respond('medium') {
  .events {
    padding-top: 30px;
    padding-bottom: 90px;

    &__date-wrap {
      margin-top: 60px;
    }

    &__picture {
      height: calc(var(--grid-column3) * 208 / 343);
      width: var(--grid-column3);
    }

    &__date-gray-wrap {
      padding-top: 3px;
      margin-left: 26px;
    }

    &__list {
      grid-row-gap: 25px;
    }

    &__info-wrap {
    }

    &__item {
      grid-column: span 3;
    }

    &__link {
      &._wide {
        padding: 16px;

        .events {
          &__picture {
            height: calc((var(--grid-column2) + var(--grid-gap) + 23px) * 158 / 260);
            width: calc(var(--grid-column2) + var(--grid-gap) + 23px);
          }

          &__arrow {
            top: 22px;
            right: 22px;
          }

          &__info-wrap {
            padding: 16px 26px 2px;
            margin-left: 16px;
            min-height: 100%;

            &:before {
              bottom: -16px;
              height: calc(100% + 32px);
            }
          }

          &__name {
            max-width: calc(var(--grid-column2) + var(--grid-gap) + (var(--grid-column) / 2));
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .events {
    &__info-wrap {
      --padding-top: 22px
    }

    &__arrow {
      top: 18px;
      right: 18px;
    }
  }
}

@include respond-down('small') {
  .events {
    padding-top: 20px;
    padding-bottom: 50px;

    &__date-wrap {
      margin-top: 50px;
    }

    &__picture {
      height: calc(var(--grid-column4) * 205 / 338);
      width: var(--grid-column4);
    }

    &__date-gray-wrap {
      padding-top: 3px;
      margin-left: 17px;
    }

    &__list {
      grid-row-gap: 16px;
    }

    &__info-wrap {
      min-height: 206px;
    }

    &__item {
      grid-column: span 4;
    }
  }
}