.team-slider {
  &__slider-list {
    &.swiper-wrapper {
      display: grid;
      grid-gap: var(--grid-gap);
    }
  }
}

@include respond-up('s-large') {
  .team-slider {
    &__slider-list {
      &.swiper-wrapper {
        grid-template-columns: repeat(4, var(--grid-column3));
      }
    }
  }
}

@include respond('medium') {
  .team-slider {
    &__slider-list {
      &.swiper-wrapper {
        grid-template-columns: repeat(2, var(--grid-column3));
      }
    }

    &__slider-item {
      &.swiper-slide {
        width: var(--grid-column3) !important;
        margin: 0 !important;
      }
    }
  }
}

@include respond-down('small') {
  .team-slider {
    &__slider-list {
      &.swiper-wrapper {
        grid-template-columns: var(--grid-column4);
        grid-row-gap: calc(var(--grid-gap) * 2);
      }
    }

    &__slider-item {
      &.swiper-slide {
        width: var(--grid-column4) !important;
      }
    }
  }
}