@include respond-up('s-large') {
  .index-contacts {
    &__contacts-card {
      padding: 80px 0;
      position: static;
      display: flex;
      column-gap: var(--grid-gap);
    }

    &__info-wrap {
      padding: 0;
    }
  }
}

@include respond('medium') {
  .index-contacts {
    &__contacts-card {
      gap: calc(var(--grid-gap) * 2);
      flex-wrap: wrap;
    }

    &__info-wrap {
      margin: 0;
    }
  }
}

@include respond-down('small') {
  .index-contacts {
    &__schedule {
      gap: var(--grid-gap);
      flex-wrap: wrap;
    }

    &__time {
      margin: 0;
    }
  }
}