.nav-subitem {

}

@include respond-up('s-large') {
  .nav-subitem {
    &_desktop {
      transform: translateY(-200%);
      opacity: 0;
      transition: opacity var(--default-timing) var(--default-transition-function), transform 1ms var(--default-transition-function);
      transition-delay: var(--default-timing);
      background: white;
      position: absolute;
      z-index: 50;
      left: 0;
      right: 0;
      width: 100%;
      top: 78px;
      height: 78px;
      box-shadow: 0px 2px 10px 0px #00000040;

      &:hover {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &_mobile {

    }

    &__nav-item,
    &__list-wrapper {
      height: inherit;
    }

    &__list {
      margin-right: -25px;
      margin-left: -25px;
    }

    &__nav-link {
      padding-left: 25px;
      padding-right: 25px;
    }

    &__nav-link,
    &__list {
      display: flex;
      flex-direction: row;
      height: inherit;
      align-items: center;
    }
  }
}

@include respond('s-large') {
  .nav-subitem {
  }
}

@include respond-up('medium') {
  .nav-subitem {
  }
}

@include respond('medium') {
  .nav-subitem {
    &__list {
      margin: 0 calc(-1 * var(--grid-spacer) - 25px) 25px;
    }

    &__nav-link {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}

@include respond-down('medium') {
  .nav-subitem {
    &__nav-link {
      span {
        color: var(--grey-text);
      }
    }

    &__list {
      display: flex;
    }

    &_desktop {
      display: none;
    }

    &_mobile {

    }
  }
}

@include respond-down('small') {
  .nav-subitem {
    &__list {
      margin: 0 calc(-1 * var(--grid-spacer)) 20px;
      flex-direction: column;
    }

    &__nav-link {
      &:first-child,
      &:last-child {
        &.header__nav-link {
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        }
      }

      &.header__nav-link {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
      }
    }
  }
}