.info-block-index {
  background: white;
  display: flex;
  position: relative;

  &__picture {
    display: flex;
    width: 88px;
    height: 62px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:before,
  &:after {
    position: absolute;
    content: '';
    background: var(--grey-line);
  }

  &:before {
    width: 23px;
    height: 2px;
  }

  &:after {
    width: 2px;
    height: 23px;
  }

  &__text {
    display: flex;
    flex-direction: column;
  }
}

@include respond-up("large") {
  .info-block-index {
  }
}

@include respond("large") {
  .info-block-index {
  }
}

@include respond-down("large") {
  .info-block-index {
  }
}

@include respond-up("s-large") {
  .info-block-index {
    grid-column: span 4;
    flex-direction: column;
    padding: 25px 35px 70px;

    &__name {
      padding-top: 40px;
    }

    &__picture {
      margin-top: 23px;
      margin-left: 18px;
    }
  }
}

@include respond("s-large") {
  .info-block-index {
  }
}

@include respond-down("s-large") {
  .info-block-index {
  }
}

@include respond-up("medium") {
  .info-block-index {
    &__description {
      padding-top: 15px;
    }
  }
}

@include respond("medium") {
  .info-block-index {
    grid-column: span 6;
    flex-direction: row;
    padding: 25px 70px 30px 25px;

    &__text {
      padding-left: 70px;
    }

    &__picture {
      flex: 0 0 88px;
      margin-top: 28px;
      margin-left: 18px;
    }
  }
}

@include respond-down("medium") {
  .info-block-index {
    &:not(:first-child) {
      margin-top: var(--grid-gap);
    }
  }
}

@include respond-down("small") {
  .info-block-index {
    grid-column: span 4;
    flex-direction: column;
    padding: 20px 25px 30px;

    &__picture {
      margin-top: 23px;
      margin-left: 18px;
    }

    &__name {
      padding-top: 25px;
    }

    &__description {
      padding-top: 12px;
    }
  }
}