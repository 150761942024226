.closest-events {
  &__button {
    margin-top: 25px;
  }
}

@include respond-down('small') {
  .closest-events {
    &__list {
      &.swiper-wrapper {
        flex-direction: column;
        row-gap: calc(var(--grid-gap) * 2);
      }
    }

    &__button {
      width: 100%;
      white-space: normal;
    }
  }
}