.index-rslider {
  &__text-inner {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    transition: transform .8s, opacity .3s;
    transition-timing-function: var(--teaser-transition-func);

    opacity: 0;
    transform: translateY(100%);

    @for $i from 2 through 100 {
      &:nth-child(#{$i}) {
        transition-delay: calc(((#{$i} - 1) * 0.1s));
      }
    }
  }

  &__pagination-list {
    position: absolute;
    z-index: 100;
    display: flex;
  }

  &__pagination-item {
    width: 39px;
    height: 3px;
    background: var(--white50);
    display: inline-block;
    transition: background var(--default-timing) var(--default-transition-function);
  }

  &__pagination-item-wrap {
    padding: 10px 0;
    margin: -10px 0;

    &:not(:first-child) {
      margin-left: 2px;
    }

    &._active {
      .index-rslider {
        &__pagination-item {
          background: white;
        }
      }
    }
  }

  &__button {
    transition: opacity var(--default-timing) var(--default-transition-function);
    opacity: 1;

    &._hidden {
      opacity: 0;
    }
  }

  &__image-item {
    margin: 0 calc(0px - var(--grid-spacer-and-indent));
    position: absolute;
    top: 0;
    left: 0;

    &._active {
      .index-rslider {
        &__image {
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    &._out-first-act {
      z-index: 50;

      .index-rslider {
        &__image {
          transform: scale(1.2);
          opacity: 0;
        }
      }
    }

    &._in-first-act {
      z-index: 70;

      .index-rslider {
        &__image {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }

  &__arrows-wrap {
    position: absolute;
    left: 0;
    z-index: 100;
  }

  &__image-wrap {
    width: calc(var(--vw, 1vw) * 100);
  }

  &__wrap {
    position: relative;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: calc(100vh - var(--header-height));
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1.5s var(--ref-cubic-func), opacity 1.5s var(--ref-cubic-func);
    opacity: 0;
  }

  &__text-item {
    position: absolute;
    left: 0;

    &._active {
      .index-rslider {
        &__text-inner {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    &._out-first-act {
      z-index: 50;

      .index-rslider {
        &__text-inner {
          opacity: 0;
          transform: translateY(100%);
        }
      }
    }

    &._in-first-act {
      z-index: 70;

      .index-rslider {
        &__text-inner {
          //opacity: 1;
          //transform: translateY(0);
        }
      }
    }
  }

  &__button {
    position: absolute;
    left: 0;
    z-index: 100;
  }

  &__image-list {
    position: relative;
    height: calc(100vh - var(--header-height));
  }
}

@include respond-up('large') {
  .index-rslider {
    &__text-item {
      width: var(--grid-column5);
    }
  }
}

@include respond-up('s-large') {
  .index-rslider {
    --header-height: 156px;

    &__pagination-list {
      left: 50%;
      transform: translateX(-50%);
      bottom: 80px;
    }

    &__arrows-wrap {
      bottom: 80px;
    }

    &__text-item {
      bottom: 410px;
    }

    &__button {
      bottom: 315px;
    }
  }
}

@include respond('s-large') {
  .index-rslider {
    &__text-item {
      width: var(--grid-column6);
    }
  }
}

@include respond('medium') {
  .index-rslider {
    --header-height: 62px;

    &__pagination-list {
      right: 0;
      bottom: 83px;
    }

    &__arrows-wrap {
      bottom: 65px;
    }

    &__text-item {
      width: var(--grid-column4);
      bottom: calc(278px);
    }

    &__button {
      bottom: 195px;
    }
  }
}

@include respond-down('small') {
  .index-rslider {
    --header-height: 58px;

    &__pagination-list {
      right: 0;
      bottom: calc(109 / 720 * var(--vh, 1vh) * 100);
    }

    &__arrows-wrap {
      bottom: calc(90 / 720 * var(--vh, 1vh) * 100);
    }

    &__text-item {
      width: var(--grid-column4);
      bottom: calc(200 / 720 * var(--vh, 1vh) * 100 + 73px);
    }

    &__button {
      bottom: calc(200 / 720 * var(--vh, 1vh) * 100);
    }
  }
}