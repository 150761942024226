.events {
  &__item {
    border: 1px solid;
  }

  &__date-gray-wrap {
    display: flex;
    margin: 0;
    padding: 0;
  }

  &__link {
    &._wide {
      padding: 0;
      border: 1px solid;

      .events {
        &__info-wrap {
          margin: 0;
        }

        &__name {
          max-width: unset;
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .events {
    &__item {
      grid-column: span 6;
    }

    &__date-wrap {
      border: unset;

      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border-bottom: 1px solid;
      }
    }

    &__link {
      &:hover {
        .events {
          &__date-wrap {
            &::before {
              border-color: var(--bvi-bg-color);
            }
          }
        }
      }
    }
  }
}

@include respond('medium') {
  .events {
    &__item {
      grid-column: span 6;
    }

    &__link {
      &._wide {
        .events {
          &__info-wrap {
            padding-bottom: 16px;

            &::before {
              content: unset;
            }
          }

          &__date-wrap {
            border-top: 1px solid;
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .events {
    &__info-wrap {
      flex: 1 0 100%;
    }

    &__link {
      &._wide {
        .events {
          &__date-wrap {
            margin-top: 25px;
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .events {
    &__date-gray-wrap {
      flex-direction: column;
      row-gap: 10px;
    }

    &__time {
      margin: 0;
      padding: 0;
    }
  }
}