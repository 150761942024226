.layout {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: calc(var(--vh, 1vh) * 100); //true-vh

  &__content,
  &__after-content {
    overflow: hidden;
  }

  &__content {
    background: var(--background-color);
  }

  .swiper-button-disabled {
    opacity: .5;
  }
}

@include respond-up('s-large') {
  :root {
    --header-height-val: 78px
  }
}

@include respond('medium') {
  :root {
    --header-height-val: 62px
  }
}

@include respond-down('medium') {
  .layout {
    &._opened {
      height: 100vh;
      overflow: hidden;
    }
  }
}

@include respond-down('small') {
  :root {
    --header-height-val: 58px
  }
}