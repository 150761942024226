.index-page {
}

@include respond-up("large") {
  .index-page {
  }
}

@include respond("large") {
  .index-page {
  }
}

@include respond-down("large") {
  .index-page {
  }
}

@include respond-up("s-large") {
  .index-page {
    &__block {
      padding-bottom: 80px;
    }
  }
}

@include respond("s-large") {
  .index-page {
  }
}

@include respond-down("s-large") {
  .index-page {
  }
}

@include respond-up("medium") {
  .index-page {
  }
}

@include respond("medium") {
  .index-page {
    &__block {
      padding-bottom: 60px;
    }
  }
}

@include respond-down("medium") {
  .index-page {
  }
}

@include respond-down("small") {
  .index-page {
    &__block {
      padding-bottom: 30px;
    }
  }
}