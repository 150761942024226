.weapons {
  &__link {
    background: var(--grey-background);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  &__weapon-name {
    align-self: flex-start;
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image-wrap {
    display: flex;
  }
}

@include respond-up('s-large') {
  .weapons {
    padding-top: 80px;
    padding-bottom: 120px;

    &__picture {
      height: calc(var(--grid-column3) * 200 / 320);
    }

    &__weapon-name {
      margin-top: 68px;
    }

    &__link {
      padding: 60px 30px 30px 30px;
    }

    &__item {
      &._tight {
        grid-column: span 4;

        .weapons {
          &__picture {
            height: calc(var(--grid-column3) * 200 / 320);
          }

          &__image-wrap {
            width: var(--grid-column3);
          }
        }
      }

      &._wide {
        grid-column: span 6;

        .weapons {
          &__picture {
            height: calc(var(--grid-column5) * 200 / 551);
          }

          &__image-wrap {
            width: var(--grid-column5);
          }
        }
      }
    }

    &__wrap {
      &:first-child {
        margin-top: 45px;
      }

      &:not(:first-child) {
        margin-top: 100px;
      }
    }

    &__list-wrap {
      &:not(:first-child) {
        margin-top: 105px;
      }
    }

    &__list {
      margin-top: 22px;
    }
  }
}

@include respond-up('medium') {
  .weapons {

  }
}

@include respond('medium') {
  .weapons {
    padding-top: 60px;
    padding-bottom: 90px;

    &__weapon-name {
      margin-top: 56px;
    }

    &__link {
      padding: 40px 25px 25px 25px;
    }

    &__item {
      &._tight {
        grid-column: span 3;

        .weapons {
          &__picture {
            height: calc((var(--grid-column2) + var(--grid-gap) + 12px) * 156 / 250);
          }

          &__image-wrap {
            width: calc((var(--grid-column2) + var(--grid-gap) + 12px));
          }
        }
      }

      &._wide {
        grid-column: span 6;

        .weapons {
          &__picture {
            height: calc((var(--grid-column4) - (var(--grid-gap) * 2)) * 156 / 430);
          }

          &__image-wrap {
            width: calc(var(--grid-column4) - (var(--grid-gap) * 2));
          }
        }
      }
    }

    &__wrap {
      &:first-child {
        margin-top: 35px;
      }

      &:not(:first-child) {
        margin-top: 70px;
      }
    }

    &__list-wrap {
      &:not(:first-child) {
        margin-top: 70px;
      }
    }

    &__list {
      margin-top: 20px;
    }
  }
}

@include respond-down('medium') {
  .weapons {

  }
}

@include respond-down('small') {
  .weapons {
    padding-top: 30px;
    padding-bottom: 50px;

    &__item {
      grid-column: span 4;

      &._tight {
        .weapons {
          &__link {
            padding: 30px 25px 25px 25px;
          }

          &__picture {
            height: calc(var(--grid-column3) * 156 / 250);
          }

          &__image-wrap {
            width: var(--grid-column3);
          }

          &__weapon-name {
            margin-top: 49px;
          }
        }
      }

      &._wide {
        .weapons {
          &__link {
            padding: 51px 13px 25px 13px;
          }

          &__picture {
            height: calc((var(--grid-column4) - var(--grid-gap) * 2) * 111 / 305);
          }

          &__image-wrap {
            width: calc(var(--grid-column4) - var(--grid-gap) * 2);
          }

          &__weapon-name {
            margin-top: 73px;
          }
        }
      }
    }

    &__wrap {
      &:first-child {
        margin-top: 22px;
      }

      &:not(:first-child) {
        margin-top: 35px;
      }
    }

    &__list-wrap {
      &:not(:first-child) {
        margin-top: 50px;
      }
    }

    &__list {
      margin-top: 18px;
    }
  }
}