.drop-menu {

}

@include respond-up('large') {
  .drop-menu {

  }
}

@include respond-up('medium') {
  .drop-menu {

  }
}

@include respond('medium') {
  .drop-menu {

  }
}

@include respond-down('medium') {
  .drop-menu {

  }

  .bvi-body[data-bvi-theme=white],
  .bvi-body[data-bvi-theme=black],
  .bvi-body[data-bvi-theme=blue],
  .bvi-body[data-bvi-theme=brown],
  .bvi-body[data-bvi-theme=green] {
    .drop-menu__nav-link{
      &:hover{
          .drop-menu__arrow {
            svg {
              background-color: var(--bvi-text-color)!important;
              path{
                fill: var(--bvi-text-color)!important;
                stroke: var(--bvi-bg-color)!important;
              }
            }
          }
      }
    }
    .drop-menu__arrow {
      svg {
        background-color: var(--bvi-bg-color)!important;
        path{
          fill: var(--bvi-bg-color) !important;
          stroke: var(--bvi-text-color)!important;
        }
      }
    }
    .drop-menu__recall{
      &:hover{
        .drop-menu__recall-icon{
          svg{
            background-color: var(--bvi-text-color)!important;
            path{
              fill: var(--bvi-bg-color)!important;
              stroke: var(--bvi-bg-color)!important;
            }
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .drop-menu {
    &__external-links{
      height: auto;
      display: flex;
      flex-wrap: wrap;
    }
  }
}