.ya-share2 {
  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.share {
  .ya-share2__container_size_m {
    .ya-share2__list_direction_horizontal{
      display: flex;
      flex-direction: column;
      .ya-share2__item_service {
        &_vkontakte {
          .ya-share2__badge {
            border-radius: unset;
            .ya-share2__icon {
              background-image: unset;
              width: 100%;
              height: auto;
              &::before {
                content: 'Вконтакте'
              }
            }
          }
          &:hover{
            .ya-share2__link{
              .ya-share2__badge{
                .ya-share2__icon{
                  filter: unset;
                }
              }
            }
          }
        }

        &_odnoklassniki {
          margin: 0;
          .ya-share2__badge {
            border-radius: unset;
            .ya-share2__icon {
              background-image: unset;
              width: 100%;
              height: auto;
              &::before {
                content: 'Однолкассники'
              }
            }
          }
          &:hover{
            .ya-share2__link{
              .ya-share2__badge{
                .ya-share2__icon{
                  filter: unset;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .ya-share2 {

  }

  .share {
    align-items: baseline;
  }
}

@include respond-up('medium') {
  .ya-share2 {

  }
}

@include respond('medium') {
  .ya-share2 {

  }
}

@include respond-down('medium') {
  .ya-share2 {

  }

  .share {
    flex-direction: column;
    gap: 10px;
  }
}

@include respond-down('small') {
  .ya-share2 {

  }

  .share {
    margin-top: 10px;
    align-items: flex-start;
  }
}