.modal {
  &__closer {
    width: var(--close-width);
    height: var(--close-width);
    border-radius: 100px;
    border: 1px solid var(--light-blue);
    position: absolute;
    transition: border-color var(--default-timing) var(--default-transition-function);

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 16px;
      height: 2px;
      background: var(--primary-blue);
      top: 50%;
      left: 50%;
    }

    &:before {
      transform: translate3d(-50%, -50%, 0) rotate(45deg);
    }

    &:after {
      transform: translate3d(-50%, -50%, 0) rotate(-45deg);
    }
  }

  //&__content {
    //overflow: scroll hidden;
    //-ms-overflow-style: none; /* IE and Edge */
    //scrollbar-width: none; /* Firefox */
    //
    //&::-webkit-scrollbar {
    //  display: none;
    //}
  //}
}

@include respond-up('s-large') {
  .modal {
    &__closer {
      --close-width: 40px;

      &:hover {
        border-color: var(--primary-blue);
      }
    }

    &__content {
      .modal-form {
        width: 687px;
        padding: 50px 60px;
      }
    }
  }
}

@include respond-up('medium') {
  .modal {
    &__closer {
      top: 20px;
      right: 25px;
    }
  }
}

@include respond('medium') {
  .modal {
    &__container {
      width: var(--grid-column5);
    }

    &__content {
      .modal-form {
        width: 580px;
        padding: 54px 50px 50px;
      }
    }
  }
}

@include respond-down('medium') {
  .modal {
    &__closer {
      --close-width: 32px;
    }
  }
}

@include respond-down('small') {
  .modal {
    &__closer {
      top: 25px;
      right: var(--grid-spacer);
    }

    &__content {
      .modal-form {
        padding: 107px var(--grid-spacer);
      }
    }
  }
}