.service-card {
  position: relative;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;

  &__name {
    padding-right: 10px;
  }

  &__button {
    position: absolute;
    z-index: 100;
  }

  &__picture {
    display: flex;
    width: 100%;
    overflow: hidden;
    border-radius: 4px 4px 0 0;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-wrap {
    display: flex;
    position: relative;
    background: white;
    border-radius: 0 0 4px 4px;

    &_category {

      &:before {
        position: absolute;
        content: '';
        background: var(--grey-line);
        width: 100%;
        height: 1px;
        left: 0;
      }
    }
  }
}

@include respond-up('s-large') {
  .service-card {

    &:hover {
      .service-card {
        &__button {
          transform: scale(1.15);
        }

        &__name {
          color: var(--primary-red);
        }

        &__image {
          transform: scale3d(1.06, 1.06, 1);
        }
      }
    }

    &__image {
      transition: transform 1s cubic-bezier(0.420, 0.000, 0.002, 1.000);
    }

    &__picture {
      &_category {
        height: calc(var(--grid-column6) * 407 / 669);
      }

      &_service {
        height: calc(var(--grid-column4) * 407 / 669);
      }
    }

    &__text-wrap {
      &_category {
        padding: 25px 35px 38px;

        &:before {
          bottom: 22px;
        }
      }

      &_service {
        padding: 25px 25px 35px;
      }

      &_price {
        .service-card__name {
          //flex: 0 0 calc(var(--grid-column4) - 35px);
        }

        //&:after {
        //  position: absolute;
        //  content: '';
        //  background: var(--grey-line);
        //  width: 1px;
        //  height: calc(100% - 22px);
        //  top: 0;
        //  left: var(--grid-column4);
        //}
      }
    }

    &__name {
      flex: 1 1 auto;

      transition: color var(--default-timing) var(--default-transition-function);
    }

    &__price {
      margin-left: calc(var(--grid-gap) * 2);
    }

    &__button {
      top: 30px;
      right: 30px;
    }
  }
}

@include respond('medium') {
  .service-card {
    &__text-wrap {
      &_category {
        padding: 16px 25px 30px
      }

      &_service {
        padding: 16px 25px 25px;
      }
    }

    &__picture {
      height: calc(var(--grid-column3) * 209 / 343);
    }
  }
}

@include respond-down('medium') {
  .service-card {
    &__button {
      top: 18px;
      right: 18px;
    }

    &__text-wrap {
      flex-direction: column;

      &_category {
        &:before {
          bottom: 18px;
        }
      }
    }

    &__price {
      margin-top: 9px;
    }
  }
}

@include respond-down('small') {
  .service-card {
    &__text-wrap {
      &_category {
        padding: 16px 25px 38px
      }

      &_service {
        padding: 16px 25px 22px;
      }
    }

    &__picture {
      height: calc(var(--grid-column4) * 202 / 338);
    }
  }
}