.slider-title {
  position: relative;

  &._noline {
    &:after {
      display: none;
    }
  }

  &._notopline {
    &:before {
      display: none;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: calc(0px - var(--grid-spacer-and-indent));
    top: 0;
    height: 1px;
    width: calc(var(--vw, 1vw) * 100);
    background: var(--grey-line);
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 1px;
    background: var(--grey-line);
  }

  &__title {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: calc(0px - var(--grid-spacer-and-indent));
      height: 1px;
      width: calc(var(--vw, 1vw) * 100);
      background: var(--grey-line);
    }
  }

  &__arrows-wrap {
    &._none {
      display: none;
    }
  }
}

@include respond-up('s-large') {
  .slider-title {
    padding: 57px 0 25px 0;
    &.partners {
      padding: 80px 0 35px 0;
      .slider-title {
        &__title {
          &:before {
            bottom: -35px;
          }
        }
      }
    }

    &:after {
      right: var(--grid-column4);
    }

    &__title {
      grid-column: 1/9;

      &:before {
        bottom: -25px;
      }
    }

    &__arrows-wrap {
      grid-column: 9/13;
      display: flex;
      justify-content: flex-end;
    }
  }
}

@include respond-up('medium') {
  .slider-title {

  }
}

@include respond('medium') {
  .slider-title {
    padding: 38px 0 23px 0;
    &.partners {
      padding: 60px 0 24px 0;
      .slider-title {
        &__title {
          &:before {
            bottom: -24px;
          }
        }
      }
    }

    &:after {
      right: var(--grid-column2);
    }

    &__title {
      grid-column: 1/5;

      &:before {
        bottom: -23px;
      }
    }

    &__arrows-wrap {
      grid-column: 5/7;
      display: flex;
      justify-content: flex-end;
    }
  }
}

@include respond-down('small') {
  .slider-title {
    padding: 30px 0 20px 0;

    &:after {
      right: 0;
    }

    &__title {
      grid-column: 1/5;

      &:before {
        bottom: -20px;
      }
    }

    &__arrows-wrap {
      display: none;
    }
  }
}