article {
  ol {
    li {
      &:before {
        color: var(--bvi-text-color);
      }
    }
  }

  ul {
    li {
      &:before {
        background: var(--bvi-text-color);
      }
    }
  }

  a {
    text-decoration: underline dashed;
  }
}

@include respond-up("s-large") {
  article {
    a {
      &:hover {
        text-decoration-color: var(--bvi-bg-color);
      }
    }
  }
}