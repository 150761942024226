.slider-nav {
  &__none {
    display: none;
  }
}

@include respond-up("large") {
  .slider-nav {
    &__slarge {
      display: none;
    }
  }
}

@include respond("large") {
  .slider-nav {
  }
}

@include respond-down("large") {
  .slider-nav {
  }
}

@include respond-up("s-large") {
  .slider-nav {
    &__tablet {
      display: none;
    }
  }
}

@include respond("s-large") {
  .slider-nav {
  }
}

@include respond-down("s-large") {
  .slider-nav {
  }
}

@include respond-up("medium") {
  .slider-nav {
  }
}

@include respond("medium") {
  .slider-nav {
  }
}

@include respond-down("medium") {
  .slider-nav {
  }
}

@include respond-down("small") {
  .slider-nav {
  }
}