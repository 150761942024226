.info-block-kids {
  flex-direction: column;
  background: white;
  display: flex;
  position: relative;

  &__picture {
    display: flex;
    width: 88px;
    height: 62px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:before,
  &:after {
    position: absolute;
    content: '';
    background: var(--grey-line);
  }

  &:before {
    width: 23px;
    height: 2px;
  }

  &:after {
    width: 2px;
    height: 23px;
  }
}

@include respond-up("large") {
  .info-block-kids {
  }
}

@include respond("large") {
  .info-block-kids {
  }
}

@include respond-down("large") {
  .info-block-kids {
  }
}

@include respond-up("s-large") {
  .info-block-kids {
    padding: 25px 35px 45px;

    &__name {
      padding-top: 40px;
    }

    &__picture {
      margin-top: 23px;
      margin-left: 18px;
    }
  }
}

@include respond("s-large") {
  .info-block-kids {
  }
}

@include respond-down("s-large") {
  .info-block-kids {
  }
}

@include respond-up("medium") {
  .info-block-kids {
    grid-column: span 3;

    &__description {
      padding-top: 15px;
    }
  }
}

@include respond("medium") {
  .info-block-kids {
    padding: 25px 25px 30px;

    &__text {
      padding-top: 25px;
    }

    &__picture {
      margin-top: 28px;
      margin-left: 18px;
    }
  }
}

@include respond-down("medium") {
  .info-block-kids {
  }
}

@include respond-down("small") {
  .info-block-kids {
    grid-column: span 2;
    padding: 20px 18px 28px;

    &__picture {
      margin-top: 23px;
      margin-left: 18px;
    }

    &__name {
      padding-top: 25px;
    }

    &__description {
      padding-top: 12px;
    }
  }
}