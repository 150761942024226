.breadcrumbs {
  overflow: hidden;

  &__list {
    flex-wrap: wrap;
    padding: 10px;
  }

  &__name{
    white-space: normal;
  }
}

@include respond-down("small") {
  .breadcrumbs {
    &__list {
      margin: 0;
    }
  }
}