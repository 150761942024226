.hamburger {
  &__menu-lines {
    position: relative;
    display: block;

    &:before {
      content: '';
      background: black;
      position: absolute;
      width: 100%;
      height: 2px;
      top: 0;
      left: 0;
      transition: transform var(--default-timing) var(--teaser-transition-func);
    }

    &:after {
      content: '';
      background: black;
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      transition: transform var(--default-timing) var(--teaser-transition-func);
    }
  }

  &__menu-button {
    display: flex;
    align-items: center;
  }

  &__wrap {
    position: relative;
    display: flex;
    height: 12px;
    width: 48px;
  }
}

@include respond-up('s-large') {
  .hamburger {
    display: none;
  }
}

@include respond('medium') {
  .hamburger {
    &__menu-lines {
      width: 35px;
      height: 10px;
    }

    &__menu-text {
      margin-left: 18px;
    }

    &._open {
      .hamburger {
        &__menu-lines {
          &:before {
            width: 28px;
            transform: rotate(45deg) translate3d(2px, 3px, 0);
          }

          &:after {
            width: 28px;
            transform: rotate(-45deg) translate3d(2px, -4px, 0);
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .hamburger {
    &__menu-lines {
      width: 35px;
      height: 8px;
    }

    &._open {
      .hamburger {
        &__menu-lines {
          &:before {
            width: 28px;
            transform: rotate(45deg) translate3d(1px, 2px, 0);
          }

          &:after {
            width: 28px;
            transform: rotate(-45deg) translate3d(2px, -4px, 0);
          }
        }
      }
    }

    &__menu-text {
      display: none;
    }

    &__wrap {
      width: 34px;
    }

    &._open {
      .hamburger__line {

        &_top {
          width: 21px;
        }

        &_bottom {
          width: 21px;
        }
      }
    }
  }
}