.content-header {
  overflow: hidden;

  &__wrap {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      background: var(--grey-line);
      bottom: 0;
      left: calc(0px - var(--grid-spacer-and-indent));
      height: 1px;
      width: calc(var(--vw, 1vw) * 100);
    }
  }

  &_default {
    .content-header {
      &__wrap {
        &:after {
          content: '';
          position: absolute;
          background: var(--grey-line);
          bottom: 0;
          width: 1px;
          height: 100%;
        }
      }

      &__tabs {
        margin-top: 31px;
      }
    }
  }

  &_event {
    .content-header {
      &__date-wrap {
        display: flex;
        align-items: flex-start;
      }

      &__label {
        position: absolute;
      }
    }
  }
}

@include respond-up('s-large') {
  .content-header {
    margin-bottom: 25px;

    &__price-text {
      grid-column: 1/4;
    }

    &_default {
      .content-header {
        &__wrap {
          padding: 60px 0;

          &:after {
            right: calc(var(--grid-column4) + var(--grid-gap));
          }
        }

        &__title {
          margin-top: 20px;
        }

        &__left-wrap {
          grid-column: 1/9;
        }

        &__right-wrap {
          grid-column: 9/13;
        }

        &__text {
          padding-top: 36px;
        }
      }
    }

    &_event {
      .content-header {
        &__wrap {
          padding: 60px 0 100px 0;
        }

        &__date-wrap {
          grid-column: 1/6;
          padding-top: 42px;
        }

        &__date {
          margin-left: 34px;
          padding-top: 7px;
        }

        &__info-wrap {
          grid-column: 6/13;
        }

        &__title {
          margin-top: 20px;
        }

        &__label {
          bottom: 50px;
          left: calc(var(--grid-column5) + var(--grid-gap));
        }
      }
    }

    &_service {
      .content-header {
        &__wrap {
          padding: 60px 0 50px;
        }

        &__button-wrap {
          grid-column: 1/3;
          display: flex;
          align-items: flex-end;
        }

        &__info-wrap {
          grid-column: 6/13;
        }

        &__title {
          margin-top: 20px;
        }

        &__price {
          margin-top: 26px;
        }
      }
    }
  }
}

@include respond('medium') {
  .content-header {
    margin-bottom: 20px;

    &__price-text {
      grid-column: 1/4;
      padding-top: 15px;
    }

    &_default {
      .content-header {
        &__wrap {
          padding: 40px 0;
          grid-row-gap: 0;

          &:after {
            right: var(--grid-column2);
          }
        }

        &__title {
          margin-top: 12px;
        }

        &__left-wrap {
          grid-column: 1/5;
          grid-row: 1;
        }

        &__right-wrap {
          grid-column: 1/5;
          grid-row: 2;
          margin-top: 20px;
        }

        &__text {
          width: calc(var(--grid-column3) + (var(--grid-column) / 2));
        }
      }
    }

    &_event {
      .content-header {
        &__wrap {
          padding: 40px 0 22px 0;
          grid-row-gap: 0;
        }

        &__date-wrap {
          grid-column: 1/7;
          grid-row: 2;
          margin-top: 28px;
        }

        &__date {
          margin-left: 26px;
          padding-top: 5px;
        }

        &__info-wrap {
          grid-column: 1/7;
          grid-row: 1;
        }

        &__title {
          margin-top: 12px;
        }

        &__label {
          bottom: 28px;
          right: 0;
        }
      }
    }

    &_service {
      .content-header {
        &__wrap {
          padding: 40px 0 28px;
        }

        &__button-wrap {
          grid-column: 5/7;
          grid-row: 1;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
        }

        &__info-wrap {
          grid-column: 1/5;
          grid-row: 1;
        }

        &__title {
          margin-top: 12px;
        }

        &__price {
          margin-top: 28px;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .content-header {
    &_default {
      .content-header {
        &__tabs {
          margin-top: 22px;
        }
      }
    }
  }
}

@include respond-down('small') {
  .content-header {
    margin-bottom: 18px;

    &__price-text {
      grid-column: 1/3;
      padding-top: 10px;
    }

    &_default {
      .content-header {
        &__wrap {
          padding: 30px 0;
          grid-row-gap: 0;

          &:after {
            right: 0;
          }
        }

        &__title {
          margin-top: 8px;
        }

        &__left-wrap {
          grid-column: 1/5;
          grid-row: 1;
        }

        &__right-wrap {
          grid-column: 1/4;
          grid-row: 2;
          margin-top: 20px;
        }
      }
    }

    &_event {
      .content-header {
        &__wrap {
          padding: 30px 0 14px 0;
          grid-row-gap: 0;
        }

        &__date-wrap {
          grid-column: 1/5;
          grid-row: 2;
          margin-top: 22px;
        }

        &__date {
          margin-left: 20px;
          padding-top: 5px;
        }

        &__info-wrap {
          grid-column: 1/5;
          grid-row: 1;
        }

        &__title {
          margin-top: 8px;
        }

        &__label {
          bottom: 22px;
          right: 0;
        }
      }
    }

    &_service {
      .content-header {
        &__wrap {
          padding: 30px 0 30px;
        }

        &__button-wrap {
          position: absolute;
          right: 0;
          bottom: 20px;
        }

        &__info-wrap {
          grid-column: 1/5;
        }

        &__title {
          margin-top: 8px;
        }

        &__price {
          margin-top: 22px;
        }
      }
    }
  }
}