.bvi-body {
    &[data-bvi-theme='white'] {
        .custom-bvi-background {
            background: #f5f5f5 !important;
        }

        .custom-bvi-color {
            color: #000 !important;

            input[type='text'] {
                &::placeholder {
                    color: #000 !important;
                }
            }
        }

        .custom-bvi-border-color {
            border-color: #000 !important;
        }
    }

    &[data-bvi-theme='black'] {
        .custom-bvi-background {
            background: #525252 !important;
        }

        .custom-bvi-color {
            color: #fff !important;

            input[type='text'] {
                &::placeholder {
                    color: #fff !important;
                }
            }
        }

        .custom-bvi-border-color {
            border-color: #fff !important;
        }
    }

    &[data-bvi-theme='blue'] {
        .custom-bvi-background {
            background: #73B3E7 !important;
        }

        .custom-bvi-color {
            color: #063462 !important;

            input[type='text'] {
                &::placeholder {
                    color: #063462 !important;
                }
            }
        }

        .custom-bvi-border-color {
            border-color: #063462 !important;
        }
    }

    &[data-bvi-theme='brown'] {
        .custom-bvi-background {
            background: #ede3a1 !important;
        }

        .custom-bvi-color {
            color: #4D4B43 !important;

            input[type='text'] {
                &::placeholder {
                    color: #4D4B43 !important;
                }
            }
        }

        .custom-bvi-border-color {
            border-color: #4D4B43 !important;
        }
    }

    &[data-bvi-theme='green'] {
        .custom-bvi-background {
            background: #51431d !important;
        }

        .custom-bvi-color {
            color: #A9E44D !important;

            input[type='text'] {
                &::placeholder {
                    color: #A9E44D !important;
                }
            }
        }

        .custom-bvi-border-color {
            border-color: #A9E44D !important;
        }
    }
}