.service-docs {
  &__item {
    border: 1px solid var(--grey-line);
    border-radius: 4px;
  }

  &__file-link {
    display: flex;
    align-items: center;
    column-gap: 20px;
    height: 100%;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 48px;
    width: 48px;
    height: 56px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__link {
    padding: 15px;
    margin: -15px;
  }

  &__text {
    text-transform: lowercase;
    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__text,
  &__link {
    font-family: var(--font);
    font-weight: 400;
    line-height: 130%;
  }

  &__text {
    color: var(--black-text);
  }

  &__link {
    color: var(--primary-blue);
  }
}

@include respond-up('s-large') {
  .service-docs {
    padding-top: 80px;

    &__list {
      grid-template-columns: repeat(3, var(--grid-column4));
    }

    &__file-link {
      padding: 30px 35px 30px 30px;
    }

    &__link-wrap {
      margin-top: 23px;
    }
  }
}

@include respond-up('medium') {
  .service-docs {
    &__list {
      display: grid;
      grid-gap: var(--grid-gap);
    }

    &__item {
      grid-column: span 1;
    }

    &__text,
    &__link {
      font-size: 18px;
    }
  }
}

@include respond('medium') {
  .service-docs {
    padding-top: 60px;

    &__list {
      grid-template-columns: repeat(2, var(--grid-column3));
    }

    &__file-link {
      padding: 24px 30px 23px 25px;
    }
  }
}

@include respond-down('medium') {
  .service-docs {
    &__link-wrap {
      margin-top: 20px;
    }
  }
}

@include respond-down('small') {
  .service-docs {
    padding-top: 30px;

    &__list {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
    }

    &__file-link {
      padding: 16px 25px 17px;
    }

    &__text,
    &__link {
      font-size: 16px;
    }
  }
}