@include respond-up('s-large') {
  .charter-docs {
    &__item {
      grid-column: span 6;
    }
  }
}

@include respond-up('medium') {
  .charter-docs {
    &__list {
      &.grid-block {
        display: flex;
        flex-direction: column;
      }
    }
  }
}