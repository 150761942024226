.text {
  &__main {
    @extend %text-main;
  }

  &__medium {
    @extend %text-medium;
  }

  &__breadcrumbs {
    @extend %text-breadcrumbs;
  }

  &__large-and-red {
    font-family: var(--font);
    font-style: normal;
    font-weight: 300;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: var(--primary-red);
  }

  &__date {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;

    &_large {
      line-height: 110%;
      font-size: var(--font-size);
    }

    &_small {
      line-height: 120%;
      font-size: 14px;
    }
  }

  &__twelve {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.02em;
    font-size: 12px;
  }

  &__thirteen {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    font-size: 13px;
  }

  &__large {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
  }

  &__body,
  &__mini {
    font-family: var(--font);
    font-size: var(--font-size);
    font-style: normal;
    font-weight: 400;
    color: var(--primary-color);
    font-stretch: 110%;
    font-variation-settings: var(--font-settings);
  }

  &__error-page {
    font-family: var(--font);
    font-size: var(--font-size);
    font-style: normal;
    color: var(--primary-color);
    font-variation-settings: var(--font-settings), 'opsz' 60;
    font-stretch: 151%;
    font-weight: 600;
    line-height: 120%;
  }

  &__body,
  &__mini {
    line-height: 140%;
  }

  &_gray {
    color: var(--grey-text);
  }

  &_black {
    color: var(--black-text);
  }

  &_red {
    color: var(--primary-red);
  }

  &_blue {
    color: var(--primary-blue);
  }

  &_white {
    color: white;
  }
}

@include respond-up("s-large") {
  .text {
    &__date {
      &_large {
        --font-size: 50px;
      }
    }
  }
}

@include respond-up("medium") {
  .text {
    &__large {
      font-size: 18px;
    }

    &__large-and-red {
      font-size: 52px;
    }

    &__body {
      --font-size: 18px;
    }

    &__mini {
      --font-size: 14px;
    }

    &__error-page {
      --font-size: 200px;
    }
  }
}

@include respond-down("medium") {
  .text {
    &__date {
      &_large {
        --font-size: 40px;
      }
    }
  }
}

@include respond-down("small") {
  .text {
    &__large-and-red {
      font-size: 42px;
    }

    &__body {
      --font-size: 16px;
    }

    &__mini {
      --font-size: 12px;
    }

    &__large {
      font-size: 17px;
    }

    &__error-page {
      --font-size: 80px;
    }
  }
}
