.policy-checkbox {
  position: relative;

  input {
    position: absolute;
    width: 19px;
    height: 19px;

    &:checked + label {
      &:after {
        transform: scale(1);
      }
    }
  }

  label {
    padding-left: 33px;
    width: 100%;
    display: block;

    &:before,
    &:after {
      content: "";
      position: absolute;
      border-radius: 100px;
    }

    &:after {
      top: 4px;
      left: 4px;
      width: 10px;
      height: 10px;
      transform: scale(0);
      transition: transform var(--default-timing) var(--default-transition-function);
      background: var(--primary-red);
    }

    &:before {
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      border: 1px solid var(--grey-line);
      transition: background var(--default-timing) var(--default-transition-function);
    }
  }
}
