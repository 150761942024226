.form-block {
  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text-wrap,
  &__form-wrap {
    position: static;
  }

  &__text-wrap {
    width: unset;
    text-align: center;
  }

  &__form-wrap {
    padding: 0;
    width: 100%;
    margin-top: 30px;
  }
}

@include respond-up('large') {
  .form-block {

  }
}

@include respond-up('medium') {
  .form-block {

  }
}

@include respond('medium') {
  .form-block {

  }
}

@include respond-down('medium') {
  .form-block {

  }
}

@include respond-down('small') {
  .form-block {

  }
}