.kids-sport {
  grid-row-gap: 0;

  &__contains {
    display: grid;
    grid-gap: var(--grid-gap);
  }

  &__contains-item {
    padding: 28px 77px 40px 28px;
    display: flex;
  }

  &__contains-item {
    background: white;
    border-radius: 4px;
  }

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__picture {
    display: flex;
    border-radius: 4px;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__includes {
    grid-row-gap: 0;
  }

  &__includes-title {
    grid-area: includes-title;
  }

  &__includes-button {
    grid-area: includes-buttons;
  }

  &__contains {
    grid-area: contains;
  }

  &__video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__video-wrap {
    display: flex;
  }

  &__video-container {
    position: relative;
  }

  &__video-button-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &__video-button {
    transition-property: opacity, transform;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-transition-function);

    &._hidden {
      opacity: 0;
    }
  }
}


@include respond-up("large") {
  .kids-sport {
  }
}

@include respond("large") {
  .kids-sport {
  }
}

@include respond-down("large") {
  .kids-sport {
  }
}

@include respond-up("s-large") {
  .kids-sport {
    &__video-button-wrap {
      transform: translate3d(-50%, calc(-50% - 35px), 0);
    }

    &__video-container {
      padding-bottom: 70px;
    }

    &__wrapper {
      padding-top: 70px;
      //padding-bottom: 80px;
    }

    &__video-wrap {
      grid-column: 7/13;
    }

    &__contains {
      grid-template-columns: repeat(2, auto);
    }

    &__includes {
      grid-template-areas:
      'includes-title includes-title includes-title includes-title contains contains contains contains contains contains contains contains'
      'includes-buttons includes-buttons includes-buttons includes-buttons contains contains contains contains contains contains contains contains';
    }

    &__description {
      margin-top: 20px;
    }

    &__text,
    &__link {
      margin-top: 40px;
    }

    &__picture {
      grid-column: 7/13;
    }

    &__top {
      grid-column: 1/6;
      position: relative;
    }

    &__micro-title {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

@include respond("s-large") {
  .kids-sport {
  }
}

@include respond-down("s-large") {
  .kids-sport {
  }
}

@include respond-up("medium") {
  .kids-sport {
    &__contains-text {
      padding-left: 24px;
    }
  }
}

@include respond("medium") {
  .kids-sport {
    &__video-button-wrap {
      transform: translate3d(-50%, calc(-50% - 32px), 0);
    }

    &__video-container {
      padding-bottom: 64px;
    }

    &__video-wrap {
      grid-column: 1/7;
    }

    &__wrapper {
      padding-top: 55px;
      //padding-bottom: 60px;
    }

    &__includes-button {
      margin-top: 40px;
    }

    &__contains {
      margin-top: 25px;
    }

    &__includes {
      grid-template-areas:
      'includes-title includes-title includes-title includes-title includes-title includes-title'
      'contains contains contains contains contains contains'
      'includes-buttons includes-buttons includes-buttons includes-buttons includes-buttons includes-buttons';
    }

    &__link {
      margin-top: 35px;
    }

    &__picture,
    &__text {
      padding-top: 50px;
    }
  }
}

@include respond-down("medium") {
  .kids-sport {
    &__description {
      padding-top: 20px;
    }

    &__about {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

@include respond-down("small") {
  .kids-sport {
    &__video-button-wrap {
      transform: translate3d(-50%, calc(-50% - 30px), 0);
    }

    &__video-container {
      padding-bottom: 60px;
    }

    &__video-wrap {
      grid-column: 1/5;
    }

    &__wrapper {
      padding-top: 20px;
      //padding-bottom: 30px;
    }

    &__includes-button {
      margin-top: 30px;
    }

    &__contains {
      margin-top: 20px;
    }

    &__contains-text {
      padding-left: 18px;
    }

    &__includes {
      grid-template-areas:
      'includes-title includes-title includes-title includes-title'
      'contains contains contains contains'
      'includes-buttons includes-buttons includes-buttons includes-buttons';
    }

    &__picture {
      margin-top: 35px;
    }

    &__link {
      margin-top: 25px;
    }

    &__text {
      padding-top: 30px;
    }
  }
}