.index-form {
  input[type='text'] {
    border: 1px solid;
  }
}

@include respond-down('small') {
  .index-form {
    &__submit {
      width: 100%;
      white-space: normal;
    }
  }
}