.footer {
  background: white;
  overflow: hidden;

  &__email {
    display: flex;
  }

  &__rclass-link {
    color: var(--black-text);
  }

  &__contacts {
    display: flex;
    flex-direction: column;
  }

  &__top-wrap {
    position: relative;
  }

  &__nav-link {
    padding: 7px 0;
    width: 100%;
    display: flex;
  }

  &__nav-list {
    margin-bottom: -7px;
    margin-top: -7px;
  }
}

@include respond-up('s-large') {
  .footer {
    &__bottom-wrap {
      padding: 30px 0 46px 0;
    }

    &__copy {
      grid-column: 1/5;
    }

    &__policy-text {
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: -3px;
        width: 100%;
        height: 1px;
        background: var(--grey-line);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform .4s var(--default-transition-function);
      }
    }

    &__policy {
      grid-column: 5/7;

      &:hover {
        .footer {
          &__policy-text {
            &:before {
              transform-origin: left;
              transform: scaleX(1);
              transition: transform .4s var(--default-transition-function);
            }
          }
        }
      }
    }

    &__rclass {
      grid-column: 11/13;
    }

    &__contacts-wrap {
      grid-column: 1/4;
      padding-top: 35px;
    }

    &__email {
      margin-top: 8px;
      transition: color var(--default-timing) var(--default-transition-function);

      &:hover {
        color: var(--primary-red);
      }
    }

    &__nav-list {
      margin-left: calc(var(--grid-column) + var(--grid-gap));
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 200px;
    }

    &__nav-item {
      width: calc(var(--grid-column3) + var(--grid-gap));;
    }

    &__nav-link {
      transition: color var(--default-timing) var(--default-transition-function);

      &:hover {
        color: var(--primary-red);
      }
    }

    &__nav-wrap {
      grid-column: 4/13;
      padding-top: 35px;
      display: flex;
      justify-content: space-between;
    }

    &__top-wrap {
      padding-top: 24px;
      padding-bottom: 140px;

      &:before {
        height: calc(100% - 24px);
        left: calc(var(--grid-column3) + var(--grid-gap));
      }
    }
  }
}

@include respond-up('medium') {
  .footer {
    &__rclass-link {
      margin-left: 4px;
    }

    &__button {
      margin-top: 40px;
    }

    &__rclass {
      display: flex;
      justify-content: flex-end;
    }

    &__contacts-wrap {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        background: var(--grey-line);
        width: calc(var(--vw, 1vw) * 100);
        height: 1px;
        top: 0;
        left: calc(0px - var(--grid-spacer-and-indent));
      }
    }

    &__top-wrap {
      &:before {
        content: '';
        position: absolute;
        background: var(--grey-line);
        width: 1px;
        bottom: 0;
      }

      &:after {
        content: '';
        position: absolute;
        background: var(--grey-line);
        width: calc(var(--vw, 1vw) * 100);
        height: 1px;
        bottom: 0;
        left: calc(0px - var(--grid-spacer-and-indent));
      }
    }
  }
}

@include respond('medium') {
  .footer {
    &__top-wrap {
      padding-top: 18px;
      padding-bottom: 30px;

      &:before {
        height: calc(100% - 18px);
        left: calc(var(--grid-column2) + (var(--grid-gap) / 2));
      }
    }

    &__copy {
      grid-column: 1/5;
      grid-row: 1;
    }

    &__policy {
      grid-column: 1/3;
      grid-row: 2;
      margin-top: 16px;
    }

    &__rclass {
      grid-column: 6/7;
      grid-row: 1;
    }

    &__contacts-wrap {
      grid-column: 1/3;
      padding-top: 25px;
    }

    &__email {
      margin-top: 10px;
    }

    &__nav-list,
    &__socials {
      margin-left: calc(var(--grid-column) - 30px);
    }

    &__nav-item {
      width: calc(var(--grid-column2) + var(--grid-gap));
    }

    &__nav-list {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 200px;
    }

    &__nav-wrap {
      grid-column: 3/7;
    }

    &__socials {
      margin-top: 80px;
    }
  }
}

@include respond-down('medium') {
  .footer {
    &__bottom-wrap {
      padding: 25px 0 35px 0;

      &.grid-block {
        grid-row-gap: 0;
      }
    }

    &__nav-wrap {
      display: flex;
      flex-direction: column;
      padding-top: 25px;
    }
  }
}

@include respond-down('small') {
  .footer {
    &__nav-item {
      width: 50%;
    }

    &__nav-list {
      margin-right: calc(-1 * var(--grid-gap));
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 180px;
    }

    &__top-wrap {
      padding: 20px 0;

      &.grid-block {
        grid-row-gap: 0;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        background: var(--grey-line);
        left: calc(0px - var(--grid-spacer));
        width: 100vw;
        height: 1px;
      }

      &:before {
        top: 20px;
      }

      &:after {
        bottom: 0;
      }
    }

    &__copy {
      grid-column: 1/5;
    }

    &__policy {
      grid-column: 1/3;
      padding-top: 12px;
    }

    &__rclass {
      grid-column: 1/3;
      padding-top: 12px;
    }

    &__contacts-wrap {
      grid-column: 1/5;
      padding-top: 25px;
    }

    &__email {
      margin-top: 12px;
    }

    &__nav-wrap {
      grid-column: 1/5;
      margin-top: 25px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        background: var(--grey-line);
        left: calc(0px - var(--grid-spacer));
        top: 0;
        width: 100vw;
        height: 1px;
      }
    }

    &__socials {
      margin-top: 50px;
    }

    &__button {
      margin-top: 20px;
    }
  }
}