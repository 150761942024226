.charter-docs {
  &__item {
    grid-column: span 4;
  }

  &__link {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    border: 1px solid var(--grey-line);
    border-radius: 4px;
    padding: 30px;
  }

  &__link-icon {
    display: flex;
  }

  &__link-title {
    margin-left: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    color: var(--black-text);
  }

  &__description {
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 145%;
      color: var(--grey-text);

      strong {
        font-size: 18px;
        color: var(--black-text);
      }
    }
  }
}

@include respond-up('s-large') {
  .charter-docs {
    padding: 70px 0 120px;

    &__link {
      padding: 30px;
      transition: background-color .4s var(--default-transition-function);

      &:hover {
        background-color: #fff;
      }
    }

    &__description {
      //margin-top: 120px;

      h4 {
        margin-top: 45px;
      }
    }
  }
}

@include respond-up('medium') {
  .charter-docs {
    &__description {
      p {
        &:not(:first-child) {
          margin-top: 25px;
        }
      }
    }
  }
}

@include respond('medium') {
  .charter-docs {
    padding: 60px 0 70px;

    &__item {
      grid-column: span 3;
    }

    &__link {
      padding: 25px;
    }

    &__description {
      margin-top: 70px;
    }
  }
}

@include respond-down('medium') {
  .charter-docs {
    &__description {
      h4 {
        margin-top: 35px;
      }
    }
  }
}

@include respond-down('small') {
  .charter-docs {
    padding: 30px 0 50px;

    &__link {
      padding: 16px 25px 17px;
    }

    &__link-title {
      font-size: 16px;
    }

    &__description {
      margin-top: 40px;

      p {
        font-size: 15px;

        strong {
          line-height: 130%;
          font-size: 16px;
        }

        &:not(:first-child) {
          margin-top: 20px;
        }
      }
    }
  }
}
