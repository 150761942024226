.about {
  &__micro-title {
    min-width: var(--grid-column2);
  }

  &__picture {
    display: flex;
    width: 100%
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
}

@include respond-up('large') {
  .about {
    &__title {
      padding-top: 70px;
    }
  }
}

@include respond-up('s-large') {
  .about {
    position: relative;

    &__micro-title {
      position: absolute;
      left: 0;
      top: 40px;
    }

    &__text-wrap {
      padding-bottom: 70px;
      grid-column: 1/6;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
    }

    &__image-wrap {
      grid-column: 7/13;
    }

    &__picture {
      height: calc(var(--grid-column6) * 679 / 669);
    }

    &__description {
      margin-top: 20px;
    }

    &__button {
      margin-top: 40px;
    }

    &__title-medium,
    &__description-medium,
    &__button-medium {
      display: none;
    }
  }
}

@include respond('s-large') {
  .about {
    &__micro-title {
      top: 20px;
    }

    &__title {
      padding-top: 50px;
    }
  }
}

@include respond('medium') {
  .about {
    grid-row-gap: 0;

    &__title-medium {
      grid-column: 1/6;
      margin-top: 40px;
    }

    &__description-medium {
      margin-top: 40px;
      grid-column: 1/4;
    }

    &__button-medium {
      margin-top: 35px;
    }

    &__image-wrap {
      grid-column: 4/7;
      margin-top: 40px;
    }

    &__picture {
      height: calc(var(--grid-column3) * 352 / 343);
    }

    &__text-wrap {
      display: none;
    }
  }
}

@include respond-down('medium') {
  .about {

  }
}

@include respond-down('small') {
  .about {
    grid-row-gap: 0;

    &__text-wrap {
      grid-column: 1/5;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-top: 30px;
    }

    &__image-wrap {
      grid-column: 1/5;
      margin-top: 35px;
    }

    &__picture {
      height: calc(var(--grid-column4) * 343 / 338);
    }

    &__description {
      margin-top: 20px;
    }

    &__button {
      margin-top: 25px;
    }

    &__title-medium,
    &__description-medium,
    &__button-medium {
      display: none;
    }
  }
}