.footer {
  &__socials{
    flex-direction: column;
  }

  &__nav-list{
    max-height: unset;
  }

  &__copy{
    &.text__thirteen{
      line-height: unset;
    }
  }

  &__policy-text{
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    &.text__thirteen{
      line-height: unset;
    }
  }

  &__rclass{
    display: flex;
    flex-wrap: wrap;
    &.text__thirteen{
      line-height: unset;
    }
  }

  &__bottom-wrap{
    display: flex;
    flex-direction: column;
  }

  &__email{
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }

  &__button{
    margin-top: 10px;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;

  }
}

@include respond-up('s-large') {
  .footer {
    &__contacts-wrap {
      grid-column: 1 / 6;
    }

    &__nav-wrap{
      grid-column: 6 / 13;
    }

    &__nav-list {
      margin-left: 0;
    }

    &__top-wrap{
      &:before {
        left: calc(var(--grid-column5) + var(--grid-gap) / 2);
      }
    }
  }
}

@include respond-up('medium') {
  .footer {

  }
}

@include respond('medium') {
  .footer {

    &__top-wrap{
      &:before{
        left: calc(var(--grid-column3) + var(--grid-gap) / 2);
      }
    }

    &__contacts-wrap {
      grid-column: 1 / 4;
    }

    &__nav-wrap {
      grid-column: 4 / 7;
    }
  }
}

@include respond-down('medium') {
  .footer {

  }
}

@include respond-down('small') {
  .footer {
    &__nav-link{
      padding: 0;
    }
  }
}