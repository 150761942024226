.service {
  &__another-list {
    &.swiper-wrapper {
      flex-direction: column;
    }
  }

  &__another-item {
    &.swiper-slide {
      margin: 0 !important;
    }
  }

  &__info-blocks-item {
    border: 1px solid;
  }
}

@include respond-up("s-large") {
  .service {
    &__sidebar,
    &__content {
      grid-column: 1 / 13;
    }

    &__info-blocks-item {
      grid-column: span 6;
    }

    &__another-item {
      &.swiper-slide {
        width: var(--grid-column12) !important;
      }
    }
  }
}

@include respond-up("medium") {
  .service {
    &__another-list {
      row-gap: var(--grid-gap);
    }
  }
}

@include respond("medium") {
  .service {
    &__another-item {
      &.swiper-slide {
        width: var(--grid-column6) !important;
      }
    }

    &__another-slider-button {
      &.button {
        width: 100%;
      }
    }

    &__sidebar,
    &__content {
      grid-column: 1/7;
    }
  }
}

@include respond-down("small") {
  .service {
    &__another-list {
      row-gap: calc(var(--grid-gap) * 2);
    }

    &__content-header-button-wrap {
      position: static;
      grid-column: 1/5;
      grid-row: 2;
    }

    &__enroll {
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
    }
  }
}