.partners-slider {
  &::before {
    z-index: 10;
  }

  &__slider-list {
    padding: 0 var(--grid-spacer-and-indent);

    &.swiper-wrapper {
      flex-direction: column;
    }
  }

  &__slider-item {
    justify-content: flex-start;
    padding: 25px var(--grid-gap);
    border: 1px solid;

    &.swiper-slide {
      height: unset;
    }
  }
}

@include respond-up('s-large') {
  .partners-slider {
    padding: 80px 0;

    &__slider-item {
      &.swiper-slide {
        width: var(--grid-column12) !important;
      }
    }
  }
}

@include respond-up('medium') {
  .partners-slider {
    &__slider-list {
      row-gap: var(--grid-gap);
    }
  }
}

@include respond('medium') {
  .partners-slider {
    padding: 50px 0;

    &__slider-item {
      &.swiper-slide {
        width: var(--grid-column6) !important;
      }
    }
  }
}

@include respond-down('small') {
  .partners-slider {
    padding: 30px 0;

    &__slider {
      &.swiper {
        overflow: visible;
      }
    }

    &__slider-list {
      row-gap: calc(var(--grid-gap) * 2);
    }

    &__slider-item {
      &.swiper-slide {
        width: var(--grid-column4) !important;
      }
    }
  }
}