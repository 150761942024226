.partners-slider {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    bottom: -1px;
    left: calc(0px - var(--grid-spacer-and-indent));
    height: 1px;
    width: calc(var(--vw, 1vw) * 100);
    background: var(--grey-line);
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  &__slider-wrap {
    margin-right: calc(0px - var(--grid-spacer-and-indent));
  }

  &__slider {
    &.swiper {
      margin-left: calc(0px - var(--grid-spacer-and-indent));
    }
  }

  &__slider-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid var(--grey-line);

    &:last-child {
      border-right: 1px solid var(--grey-line);
    }
  }
}

@include respond-up('large') {
  .partners-slider {
    &__slider-item {
      &.swiper-slide {
        height: 20vw;
      }
    }
  }
}

@include respond('s-large') {
  .partners-slider {
    &__slider-item {
      &.swiper-slide {
        height: 25vw;
      }
    }
  }
}

@include respond-up('medium') {
  .partners-slider {
    &__image-wrap {
      width: 120px;
    }

    &__picture {
      height: 120px;
    }
  }
}

@include respond('medium') {
  .partners-slider {
    &__slider-item {
      &.swiper-slide {
        height: 34vw;
      }
    }
  }
}

@include respond-down('medium') {
  .partners-slider {

  }
}

@include respond-down('small') {
  .partners-slider {
    &__slider-item {
      &.swiper-slide {
        height: 47vw;
      }
    }

    &__image-wrap {
      width: 100px;
    }

    &__picture {
      height: 100px;
    }

    &.swiper-slide {
      width: 25vw;
      height: 25vh;
    }
  }
}