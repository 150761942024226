:root {
  --modal-bg: rgba(0, 0, 0, 0.3);
  --modal-content-radius: 4px;
  --modal-content-shadow: none;
  --modal-content-padding: 30px;
  --modal-content-background: #fff;
  --modal-closer-color: white;
  --modal-closer-size: 30px;
  --modal-preloader-size: 30px;
}

.modal {
  &__bg {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 10000;
    top: 0;
    left: 0;
    background: var(--t);
    transition: background .4s var(--teaser-transition-func);

    &._opened {
      background: var(--modal-bg);
    }

    &._closed {
      background: var(--t);
    }
  }

  &__layout {
    position: relative;
    z-index: 11000;
    cursor: default;
    margin: 0 auto;
    min-height: 100%;
    display: flex;
    transition-delay: 0s;
    transition: transform .4s var(--teaser-transition-func);
  }

  &__container {
    border-radius: var(--modal-content-radius);
    box-shadow: var(--modal-content-shadow);
    position: relative;
    display: flex;
    align-items: center;
    background: var(--modal-content-background);
    width: fit-content;

    &._opened {
    }

    &._closed {
    }
  }

  &__content {
    //overflow: scroll;
    display: flex;
    width: 100%;
    height: 100%;

    img {
      max-width: 100%;
    }
  }
}

body.modal-loading {
  overflow: hidden;
  //--preloader-size: 100px;

  //пульсирующий кружочек раньше был
  &__loader {
  }

  &__content {
    overflow: scroll;
    height: 100%;
    width: 100%;
  }
}

@include respond-up('s-large') {
  .modal {
    &__container {
      min-height: 520px;
    }

    &__layout {
      justify-content: center;
      align-items: center;

      &._opened {
      }

      &._closed {
      }
    }
  }
}

@include respond('s-large') {
  .modal {
  }
}

@include respond-up('medium') {
  .modal {
  }
}

@include respond('medium') {
  .modal {
    &__layout {
      justify-content: center;
      align-items: center;
    }

    &__container {
      min-height: 504px;
    }
  }
}

@include respond-down('medium') {
  .modal {
    &__layout {
      &._opened {
      }

      &._closed {
      }
    }
  }
}

@include respond-down('small') {
  .modal {
    &__container {
      width: calc(var(--vw, 1vw) * 100);
      height: 100vh;
    }
  }
}