.advantages {
  &__card {
    transition: opacity var(--default-timing) .1s var(--default-transition-function);
    opacity: 0;
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__picture {
    display: flex;
    width: 100%;
    border-radius: 250px;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image-wrap {
    position: relative;
    align-self: center;
  }

  &__plus {
    position: absolute;
    top: 0;
  }

  &__number {
    font-family: var(--font);
    font-style: normal;
    font-weight: 250;
    line-height: 100%;
    font-size: var(--number-size);
    position: absolute;
  }

  &__slider-item {
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    &:hover {
      .advantages {
        &__card {
          opacity: 1;
        }
      }
    }

    &:nth-child(3n + 1) {
      background: var(--primary-red);

      .advantages {
        &__card {
          background: var(--primary-red);
        }
      }
    }

    &:nth-child(3n + 2) {
      background: white;

      .advantages {
        &__plus {
          background: var(--grey-background);
        }

        &__card {
          background: white;
        }

        &__number {
          color: #D9D9D9;
        }
      }
    }

    &:nth-child(3n + 3) {
      background: var(--primary-blue);

      .advantages {
        &__card {
          background: var(--primary-blue);
        }
      }
    }

    &:nth-child(3n + 1),
    &:nth-child(3n + 3) {
      .advantages {
        &__slider-title,
        &__card-title,
        &__card-text,
        &__number {
          color: white;
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .advantages {
    --number-size: 148px;

    &__card {
      padding: 35px 32px 50px;
    }

    &__slider-item {
      cursor: pointer;
      padding: 35px 32px 23px;

      &.swiper-slide {
        height: calc(var(--grid-column4) * 443 / 439);
      }

      &:hover {
        .advantages {
          &__plus {
            transform: scale(1.15);
          }
        }
      }
    }

    &__slider-wrap {
      margin-top: 35px;
    }

    &__picture {
      height: var(--grid-column2);
    }

    &__image-wrap {
      width: var(--grid-column2);
      margin-top: 60px;
    }

    &__plus {
      right: 22px;
    }

    &__number {
      left: 32px;
      bottom: 23px;
    }
  }
}

@include respond-up('medium') {
  .advantages {
    &__arrows-wrap {
      display: none;
    }
  }
}

@include respond('medium') {
  .advantages {
    &__slider-list {
      &.swiper-wrapper {
        display: grid;
        grid-template-columns: repeat(var(--grid-columns-number), var(--grid-column));
        grid-gap: var(--grid-gap);
      }
    }

    &__slider-item {
      grid-column: span 3;

      &.swiper-slide {
        height: calc(var(--grid-column3) * 343 / 345);
      }
    }

    &__slider-wrap {
      margin-top: 25px;
    }

    &__picture {
      height: calc((var(--grid-column) * 1.5) + var(--grid-gap));
    }

    &__image-wrap {
      width: calc((var(--grid-column) * 1.5) + var(--grid-gap));
    }
  }
}

@include respond-down('medium') {
  .advantages {
    --number-size: 130px;

    &__card,
    &__slider-item {
      padding: 25px 16px 13px 16px;
    }

    &__slider-item {
      min-height: 345px;
    }

    &__image-wrap {
      margin-top: 42px;
    }

    &__plus {
      right: 12%;
    }

    &__number {
      left: 16px;
      bottom: 13px;
    }
  }
}

@include respond-down('small') {
  .advantages {
    &__slider-wrap {
      margin-top: 18px;
    }

    &__picture {
      height: var(--grid-column2);
    }

    &__image-wrap {
      width: var(--grid-column2);
    }

    &__slider-wrap {
      margin-right: calc(0px - var(--grid-spacer-and-indent));
    }

    &__slider {
      &.swiper {
        margin-left: calc(0px - var(--grid-spacer-and-indent));
        padding: 0 var(--grid-spacer-and-indent);
      }
    }

    &__slider-wrap {
      position: relative;
    }

    &__arrows-wrap {
      position: absolute;
      right: var(--grid-spacer-and-indent);
      top: -56px;
    }
  }
}