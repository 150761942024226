button {
  display: inline-block;
  border: none;
  text-transform: uppercase;
}

a.button,
span.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

a.button__round,
span.button__round {
  &_close {
    width: var(--close-width);
  }

  &_plus {
    width: var(--plus-width);
  }
}

button, .button {
  padding: 0;
  margin: 0;
  text-align: center;
  transition: var(--default-transition);
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 2px;
  color: white;

  &__with-icon {
    .button {
      &__text {
        font-size: 14px;
      }
    }
  }

  &__text {
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
  }

  &_blue,
  &_red {
    transition: background-color .9s cubic-bezier(0.4, 0, 0, 1);
  }

  &_blue {
    background: var(--primary-blue);
  }

  &_red {
    background: var(--primary-red);
  }

  &_white {
    background: white;
  }

  &__header {
    border: 1px solid var(--light-blue);
  }

  &__footer {
    padding: 15px 24px;

    .button {
      &__text {
        font-size: 15px;
        text-transform: uppercase;
        letter-spacing: 0.02em;
      }
    }
  }

  &__with-icon {
    .button {
      &__icon-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__round {
    border-radius: 100px;

    &_close {
      border: 1px solid var(--light-blue);
      position: relative;

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 16px;
        height: 2px;
        background: var(--primary-blue);
        top: 50%;
        left: 50%;
      }

      &:before {
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
      }

      &:after {
        transform: translate3d(-50%, -50%, 0) rotate(-45deg);
      }
    }

    &_play {
      .button {
        &__icon-wrap {
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &__icon {
          display: flex;
        }
      }
    }

    &_plus {
      position: relative;

      &:before,
      &:after {
        content: '';
        position: absolute;
        background: var(--black-text);
        height: 2px;
        top: 50%;
        left: 50%;
      }

      &:before {
        transform: translate3d(-50%, -50%, 0);
      }

      &:after {
        transform: translate3d(-50%, -50%, 0) rotate(90deg);
      }
    }

    &_arrow {
      .button {
        &__icon-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &__icon {
          display: flex;
        }
      }
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
  }

  &__with-icon,
  &__plus {
    .button {
      &__text {
        color: var(--black-text);
        text-transform: uppercase;
        letter-spacing: 0.02em;
      }

      &__plus-wrap {
        background: var(--primary-red);
        display: inline-block;
        border-radius: 100px;
        position: relative;

        &:before,
        &:after {
          content: '';
          position: absolute;
          height: 2px;
          background: white;
          top: 50%;
          left: 50%;
        }

        &:before {
          transform: translate3d(-50%, -50%, 0);
        }

        &:after {
          transform: translate3d(-50%, -50%, 0) rotate(90deg);
        }
      }
    }
  }

  &__arrow {
    color: var(--black-text);

    .button {
      &__text {
        text-transform: uppercase;
        letter-spacing: 0.02em;
      }

      &__icon-wrap {
        display: inline-block;
      }

      &__icon {
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        &_white {
          background: white;
        }

        &_grey {
          background: var(--grey-background);
        }
      }

      &__wrap {
        display: flex;
        align-items: baseline;
      }
    }
  }

  &__link {
    color: var(--primary-red);

    .button {
      &__text {
        font-weight: 400;
        line-height: 145%;
      }

      &__link-text {
        position: relative;

        &:before {
          content: '';
          width: 100%;
          height: 1px;
          display: block;
          position: absolute;
          background-color: var(--primary-red);
          bottom: -2px;
          transform-origin: right;
        }

        &:after {
          content: '';
          width: 100%;
          height: 1px;
          display: block;
          position: absolute;
          background-color: var(--primary-red);
          bottom: -2px;
          transform: scaleX(0);
          transform-origin: left;
        }
      }
    }
  }
}

@include respond-up('s-large') {
  button {
    font-size: 14px;
    letter-spacing: 0.02em;
  }

  .button {
    &__with-icon {
      margin-top: 25px;

      .button {
        &__icon-wrap {
          transition: transform var(--default-timing) var(--default-transition-function);
          margin-right: 20px;
        }
      }
    }

    &__text-rotate {
      transition: opacity .3s cubic-bezier(0.4, 0, 0, 1), transform .6s cubic-bezier(0.4, 0, 0, 1);
    }

    &__text-rotate-wrap {
      overflow: hidden;
      display: flex;
    }

    &__text-rotate {
      position: relative;

      &_top {
        opacity: 1;
      }

      &_clone {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -100%;
        opacity: 0;
      }
    }

    &_red {
      &:hover {
        background: #EA0505;
      }
    }

    &_blue {
      &:hover {
        background: #036AE2;
      }
    }

    &__arrow {
      &:hover {
        .button {
          &__text-rotate {
            &_wrapper {
              transform: translateY(-100%);
            }

            &_top {
              opacity: 0;
            }

            &_clone {
              opacity: 1;
            }
          }
        }
      }

      .button {
        &__icon {
          width: 40px;
          height: 40px;
        }

        &__text-rotate-wrap {
          margin-left: 20px;
        }

        &__text {
          font-size: 14px;
        }
      }
    }

    &__header {
      color: var(--primary-blue);
      padding: 14px 28px;

      &:hover {
        border-color: var(--primary-blue);
      }

      .button {
        &__text {
          font-size: 15px;
          letter-spacing: 0.02em;
        }

        &__icon-wrap {
          display: inline-block;
        }

        &__icon {
          width: 22px;
          height: 22px;
          border-radius: 100px;
          background: var(--primary-blue);
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 10px;
            height: 10px;

            path {
              fill: white;
            }
          }
        }

        &__text {
          margin-left: 10px;
        }
      }
    }

    &__round {
      &_close {
        --close-width: 40px;
        height: 40px;

        &:hover {
          border-color: var(--primary-blue);
        }
      }

      &_play {
        &:hover {
          transform: scale(1.12);
        }
      }

      &_plus {
        --plus-width: 40px;
        height: 40px;

        &:hover {
          transform: scale(1.15);
        }

        &:before,
        &:after {
          width: 14px;
        }
      }

      &_arrow {
        transition: transform var(--default-timing) var(--default-transition-function);

        .button {
          &__icon-wrap {
            width: 40px;
            height: 40px;
          }
        }

        &:hover {
          transform: scale(1.15);
        }
      }
    }

    &__link {
      .button {
        &__text {
          font-size: 16px;
        }
      }

      &.underline {
        .button {
          &__link-text {
            &::after {
              transition: transform 0.4s var(--teaser-transition-func) 0.4s;
              transform: scaleX(1);
            }

            &::before {
              transition: transform 0.4s var(--teaser-transition-func);
              transform: scaleX(0);
            }
          }
        }
      }
    }

    &__plus {
      .button {
        &__text {
          font-size: 14px;
        }
      }

      &:hover {
        .button {
          &__plus-wrap {
            transform: scale(1.1);
          }
        }
      }

      .button {
        &__plus-wrap {
          width: 52px;
          height: 52px;
          transition: transform var(--default-timing) var(--default-transition-function);

          &:before,
          &:after {
            width: 18px;
          }
        }

        &__text {
          margin-left: 20px;
        }
      }
    }

    &__footer {
      &:hover {
        .button {
          &__text-rotate {
            &_wrapper {
              transform: translateY(-100%);
            }

            &_top {
              opacity: 0;
            }

            &_clone {
              opacity: 1;
            }
          }
        }
      }
    }

    &__square {
      padding: 20px 24px;

      .button {
        &__text {
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: 0.02em;
        }
      }

      &:hover {
        .button {
          &__text-rotate {
            &_wrapper {
              transform: translateY(-100%);
            }

            &_top {
              opacity: 0;
            }

            &_clone {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .button {
    &__with-icon {
      .button {
        &__icon-wrap {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

@include respond('medium') {
  .button {
    &__with-icon {
      margin-top: 20px;

      .button {
        &__icon-wrap {
          margin-right: 20px;
        }
      }
    }
  }
}

@include respond-down('medium') {
  button {
    font-size: 13px;
    letter-spacing: 0.02em;
  }

  .button {
    &__header {
      border-radius: 100px;

      .button {
        &__text {
          display: none;
        }

        &__icon-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
        }

        &__icon {
          display: flex;
        }
      }
    }

    &__round {
      &_close {
        --close-width: 32px;
        height: 32px;
      }

      &_plus {
        --plus-width: 34px;
        height: 34px;

        &:before,
        &:after {
          width: 12px;
        }
      }

      &_arrow {
        .button {
          &__icon-wrap {
            width: 32px;
            height: 32px;
          }

          &__icon {
            svg {
              width: 8px;
              height: 8px;
            }
          }
        }
      }
    }

    &__plus {
      .button {
        &__plus-wrap {
          width: 40px;
          height: 40px;

          &:before,
          &:after {
            width: 14px;
          }
        }

        &__text {
          margin-left: 18px;
          font-size: 13px;
        }
      }
    }

    &__text-rotate_clone {
      display: none;
    }

    &__square {
      border-radius: 4px;
      padding: 17px 20px;

      .button {
        &__text {
          font-size: 13px;
          text-transform: uppercase;
          letter-spacing: 0.02em;
        }
      }
    }

    &__arrow {
      .button {
        &__icon {
          width: 32px;
          height: 32px;
        }

        &__text-rotate-wrap {
          margin-left: 16px;
        }

        &__text {
          font-size: 13px;
        }
      }
    }

    &__link {
      .button {
        &__text {
          font-size: 15px;
        }
      }
    }
  }
}

@include respond-down('small') {
  .button {
    &__with-icon {
      margin-top: 25px;

      .button {
        &__icon-wrap {
          margin-right: 16px;
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}
