.image-block {
  height: unset;
  flex-direction: column;
  padding: 0;

  &__badges {
    position: static;
    transform: unset;
    margin-top: 25px;

    svg {

    }
  }

  &__text {
    width: unset;
  }
}

@include respond-up('medium') {
  .image-block {
    &__text {
      margin: 0;
      align-items: flex-start;
    }
  }
}

@include respond-down('small') {
  .image-block {
    flex-direction: column;
  }
}