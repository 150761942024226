.event {
  grid-row-gap: 0;

  &__another-list {
    align-items: stretch;
  }

  &__another-item {
    &.swiper-slide {
      height: auto;
    }
  }

  &__participate {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__results-table {
    display: flex;
  }

  &__results {
    table {
      width: 100%;

      tr {
        border-bottom: 1px solid var(--grey-line);

        td {
          @extend %text-main;
        }

        &:nth-child(odd) {
          td {
            background: var(--grey-background);
            color: var(--primary-color);
          }
        }

        &:nth-child(even) {
          td {
            background: white;
            color: var(--primary-color);
          }
        }

        &:first-child {
          td {
            background: var(--primary-red);
            color: white;
          }
        }
      }

      td {
        padding: 14px 20px;

        &:not(:last-child) {
          border-right: 1px solid var(--grey-line);
        }
      }
    }
  }

  &__pictures {
    display: flex;
    justify-content: space-between;
  }

  &__picture {
    display: flex;
    border-radius: 4px;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__sidebar,
  &__sidebar-part {
    display: flex;
    flex-direction: column;
  }

  &__sidebar-line {
    display: flex;
    justify-content: space-between;
  }

  &__sidebar-line {
    &:not(:first-child) {
      padding-top: 10px;
    }
  }

  &__sidebar-part {
    border-bottom: 1px solid var(--grey-line);
  }
}

@include respond-up("large") {
  .event {
  }
}

@include respond("large") {
  .event {
  }
}

@include respond-down("large") {
  .event {
  }
}

@include respond-up("s-large") {
  .event {
    padding-top: 70px;
    padding-bottom: 120px;

    &__results {
      table {
        margin-top: 35px;
      }
    }

    &__picture-first {
      width: var(--grid-column4);
      height: calc(319 / 439 * var(--grid-column4));
    }

    &__picture-second {
      width: var(--grid-column3);
      height: calc(235 / 323 * var(--grid-column3));
    }

    &__main-picture {
      width: 100%;
      height: calc(475 / 783 * var(--grid-column7));
    }

    &__sidebar-part {
      padding-bottom: 30px;
      margin-bottom: 30px;
    }

    &__sidebar {
      grid-column: 1/5;
    }

    &__main-picture,
    &__content {
      grid-column: 6/13;
    }

    &__results,
    &__slider-wrap {
      grid-column: 1/13;
    }

    &__share-block {
      grid-column: 1/13;
      margin-top: 58px;
    }

    &__another-events {
      grid-column: 1/13;
      margin-top: 100px;
    }

    &__another-slider-wrap {
      margin-top: 35px;
    }

    &__another-item {
      &.swiper-slide {
        width: var(--grid-column4);
      }

      //&:not(:first-child) {
      //  margin-left: var(--grid-gap);
      //}
    }
  }
}

@include respond("s-large") {
  .event {
  }
}

@include respond-down("s-large") {
  .event {
  }
}

@include respond-up("medium") {
  .event {
    &__slider-wrap {
      margin-top: 120px;
    }

    &__pagination {
      display: none;
    }
  }
}

@include respond("medium") {
  .event {
    padding-top: 40px;
    padding-bottom: 90px;

    &__results {
      table {
        margin-top: 25px;
      }
    }

    &__picture-first {
      width: var(--grid-column3);
      height: calc(249 / 343 * var(--grid-column3));
    }

    &__picture-second {
      width: var(--grid-column2);
      height: calc(163 / 224 * var(--grid-column2));
    }

    &__main-picture {
      width: 100%;
      height: calc(424 / 700 * var(--grid-column6));
    }

    &__content {
      grid-column: 2/7;
    }

    &__sidebar {
      margin-top: 50px;
    }

    &__main-picture,
    &__sidebar,
    &__results,
    &__slider-wrap,
    &__share-block {
      grid-column: 1/7;
    }

    &__share-block {
      margin-top: 56px;
    }

    &__another-events {
      grid-column: 1/7;
      margin-top: 76px;
    }

    &__another-slider-wrap {
      margin-top: 25px;
    }

    &__another-item {
      //&:nth-child(n+3) {
      //  display: none;
      //}

      //&:not(:first-child) {
      //  margin-left: var(--grid-gap);
      //}

      &.swiper-slide {
        width: var(--grid-column3);
      }
    }
  }
}

@include respond-down("medium") {
  .event {
    &__results-table {
      margin: 0 calc(0px - var(--grid-spacer));
      padding: 0 var(--grid-spacer) 1px;
      overflow: scroll hidden;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      table {
        width: auto;
        max-width: var(--grid-column10);

        tr {
          td {
            white-space: nowrap;
          }
        }
      }
    }

    &__main-picture {
      order: 1;
    }

    &__content {
      order: 3;
    }

    &__sidebar {
      order: 2;
    }

    &__slider-wrap {
      order: 6;
    }

    &__share-block {
      order: 7;
    }

    &__results {
      order: 5;
    }

    &__another-events {
      order: 8;
    }

    &__sidebar-part {
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
  }
}

@include respond-down("small") {
  .event {
    padding-top: 20px;
    padding-bottom: 50px;

    &__results {
      table {
        margin-top: 18px;
      }
    }

    &__picture-first,
    &__picture-second {
      width: var(--grid-column2);
      height: calc(116 / 160 * var(--grid-column2));
    }

    &__main-picture {
      width: 100%;
      height: calc(204 / 338 * var(--grid-column4));
    }

    &__main-picture,
    &__sidebar,
    &__content,
    &__slider-wrap,
    &__results,
    &__share-block {
      grid-column: 1/5;
    }

    &__sidebar {
      margin-top: 30px;
    }

    &__slider-wrap {
      margin-top: 40px;
    }

    &__share-block {
      margin-top: 26px;
      grid-row-gap: 0;
    }

    &__another-events {
      grid-column: 1/5;
      margin-top: 36px;
    }

    &__another-slider-wrap {
      position: relative;
      margin-top: 18px;
    }

    &__another-item {
      &.swiper-slide {
        width: var(--grid-column4);
      }
    }

    &__another-slider {
      &.swiper {
        margin: 0 calc(0px - var(--grid-spacer-and-indent));
        padding-right: calc(var(--grid-spacer-and-indent) * 2);
      }
    }

    &__another-list {
      padding-left: var(--grid-spacer-and-indent);
    }

    &__another-item {
      //&:not(:last-child) {
      //  margin-right: var(--grid-spacer-and-indent);
      //}
    }
  }
}