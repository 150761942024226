.weapons-modal {
  width: 100%;
  padding: 0;

  &__text {
    width: 100%;
  }
}

@include respond-up("large") {
  .weapons-modal {
    &__picture {
      width: var(--grid-column5);
    }

    &__image {
      width: 100%;
    }
  }
}

@include respond("medium") {
  .weapons-modal {
    &__image-wrap {
      width: var(--grid-column6);
    }
  }
}

@include respond-down("small") {
  .weapons-modal {
    &__image-wrap {
      width: var(--grid-column4);
    }
  }
}