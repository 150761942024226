.service-card {
  border: 1px solid;
}

@include respond-up('s-large') {
  .service-card {
    &__text-wrap {
      padding-bottom: 25px;
    }
  }
}

@include respond-up('medium') {
  .service-card {

  }
}

@include respond('medium') {
  .service-card {
    &__text-wrap {
      padding-top: 30px;
    }
  }
}

@include respond-down('medium') {
  .service-card {
    &__text-wrap{
      flex-direction: row;
      align-items: center;
    }

    &__price{
      margin-top: 0;
    }
  }
}

@include respond-down('small') {
  .service-card {
    &__text-wrap {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}