.policy-checkbox {
  input {
    &:checked + label {
      &::before {
        background: var(--bvi-text-color);
      }
    }
  }

  label {
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    &::before {
      border-radius: unset;
      position: static;
    }

    &::after {
      content: unset;
    }
  }

  &__link {
    text-decoration: underline dashed;
    text-underline-offset: 4px;
  }
}

@include respond-up('medium') {
  .policy-checkbox {
    label {
      margin: -20px;

      &::before {
        top: 20px;
      }
    }
  }
}

@include respond-down('small') {
  .policy-checkbox {
    label {
      margin: -15px;

      &::before {
        top: 15px;
      }
    }
  }
}