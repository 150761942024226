.contacts {
  &__info-list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    width: unset;
  }

  &__title {
    line-height: 100%;
  }

  &__value {
    word-break: break-all;
  }

  &__schedule-wrap {
    display: flex;
    flex-direction: column;
  }

  &__time {
    margin-left: 0;
  }

  &__socials {
    flex-direction: column;
    row-gap: 10px;
  }
}

@include respond-down('small') {
  .contacts {
    &__button {
      &.button {
        white-space: unset;
      }
    }
  }
}