.about {
  &.grid-block {
    display: flex;
    flex-direction: column;
  }
}

@include respond-up('s-large') {
  .about {
    &__micro-title {
      position: relative;
      top: unset;
    }

    &__title {
      padding-top: 0;
    }
  }
}

@include respond-down('small') {
  .about {
    &__button {
      .button {
        width: 100%;
        white-space: normal;
      }
    }
  }
}