.advantages {
  &__slider-item {
    cursor: unset;
  }

  &__card {
    padding: 0;
  }

  &__card {
    opacity: 1;
    position: static;
  }

  &__number {
    position: static;
    font-weight: 400;
  }

  &__slider-list {
    display: flex;
    flex-direction: column;
    row-gap: var(--grid-gap);
  }

  &__slider-item {
    flex-direction: row;
    column-gap: var(--grid-gap);
    min-height: unset;

    &.swiper-slide {
      height: unset;
      padding: 0;
      margin: 0 !important;
      width: unset !important;
    }
  }
}

@include respond('medium') {
  .advantages {
    &__number {
      line-height: 140%;
    }
  }
}

@include respond-down('small') {
  .advantages {
    &__slider-list {
      row-gap: calc(var(--grid-gap) * 2);
    }

    &__number {
      line-height: 130%;
    }
  }
}