.index-form {
  &__submit {
   width: 100%;
  }
}

@include respond-up('s-large') {
  .index-form {

  }
}

@include respond-up('medium') {
  .index-form {
    &__form-bottom{
      margin-top: 25px;
    }

    &__checkbox-wrap {
      margin-top: 35px;
    }
  }
}

@include respond('medium') {
  .index-form {
    &__field {
      &:not(:first-child) {
        margin-top: 45px;
      }
    }
  }
}

@include respond-down('medium') {
  .index-form {

  }
}

@include respond-down('small') {
  .index-form {
    &__form-bottom {
      margin-top: 30px;
    }

    &__checkbox-wrap {
      margin-top: 30px;
    }

    &__field {
      &:not(:first-child) {
        margin-top: 40px;
      }
    }
  }
}