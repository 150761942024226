.modal-form {
  &__checkbox-wrap {
    display: flex;
  }
}

@include respond-up('medium') {
  .modal-form {
    &__submit {
      margin-top: 20px;
    }
  }
}

@include respond-down('small') {
  .modal-form {
    &__submit {
      margin-top: 15px;
    }
  }
}