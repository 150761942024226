.index-contacts {
  position: relative;

  &__map-block {
    margin: 0 calc(0px - var(--grid-spacer-and-indent));
    width: calc(var(--vw, 1vw) * 100);
    overflow: hidden;
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
  }

  &__contacts-card {
    z-index: 100;
  }

  &__value {
    margin-top: 10px;
  }

  &__time {
    margin-left: 37px;
  }

  &__schedule {
    display: flex;

    &:not(:first-child) {
      margin-top: 6px;
    }
  }
}

@include respond-up('s-large') {
  .index-contacts {
    &__image {
      border-radius: 4px 4px 0 0;
    }

    &__image-wrap {
      width: var(--grid-column4);
    }

    &__picture {
      height: calc(var(--grid-column4) * 229/439);
    }

    &__map-block {
      height: 720px;
    }

    &__contacts-card {
      position: absolute;
      left: 0;
      top: 100px;
    }

    &__info-wrap {
      background: white;
      border-radius: 0 0 4px 4px;
      padding: 35px 35px 40px;
    }
  }
}

@include respond-up('medium') {
  .index-contacts {
    &__schedule-wrap,
    &__address-wrap {
      margin-top: 20px;
    }

    &__day {
    }
  }
}

@include respond('medium') {
  .index-contacts {
    &__image-wrap {
      width: calc(var(--grid-column3) - 35px);
    }

    &__picture {
      height: calc((var(--grid-column3) - 35px) * 160/307);
    }

    &__map-block {
      height: 500px;
      margin-top: 60px;
    }

    &__contacts-card {
      display: flex;
      padding-top: 70px;
    }

    &__info-wrap {
      margin-left: 50px;
    }
  }
}

@include respond-down('medium') {
  .index-contacts {
    &__image {
      border-radius: 4px;
    }
  }
}

@include respond-down('small') {
  .index-contacts {
    &__image-wrap {
      width: var(--grid-column3);
    }

    &__picture {
      height: calc(var(--grid-column3) * 130/249);
    }

    &__map-block {
      height: 450px;
      margin-top: 40px;
    }

    &__contacts-card {
      display: flex;
      flex-direction: column;
      padding-top: 50px;
    }

    &__info-wrap {
      margin-top: 25px;
    }

    &__schedule-wrap,
    &__address-wrap {
      margin-top: 18px;
    }

    &__day {
    }
  }
}