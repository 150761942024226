button, .button {
  padding: 15px 30px;
  margin: 0;
  text-align: center;
  transition: var(--default-transition);
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  border: 1px solid;

  &__with-icon {
    .button {
      &__icon-wrap {
        display: none;
      }
    }
  }

  &__header {
    .button {
      &__icon-wrap {
        display: none;
      }

      &__text {
        margin: unset;
      }
    }
  }

  &__round {
    border-radius: unset;

    &_play {
      .button {

      }
    }

    &_arrow {
      .button {

      }
    }

    &_close {
      width: fit-content;
      height: fit-content;

      &::before {
        content: 'Закрыть';
        position: static;
        transform: unset;
        background: unset;
        width: fit-content;
        height: fit-content;
      }

      &::after {
        content: unset;
      }
    }
  }

  &__arrow {
    .button {
      &__icon-wrap {
        display: none;
      }

      &__text-rotate-wrap {
        margin: unset;
      }
    }
  }

  &__plus {
    .button {
      &__text {
        margin: unset;
      }
    }
  }

  &__plus-wrap {
    display: none;
  }

  &__text-rotate {
    &_clone {
      display: none;
    }
  }
}

@include respond-up('s-large') {
  button, .button {
    &__arrow,
    &__footer,
    &__square {
      &:hover {
        .button {
          &__text-rotate {
            &_wrapper {
              transform: unset;
            }

            &_top {
              opacity: 1;
            }
          }
        }
      }
    }

    &__round {
      &_play,
      &_plus,
      &_arrow {
        &:hover {
          transform: scale(1);
        }
      }

      &_play {
        &:hover {
          .button {
            &__icon {
              svg {
                background: var(--bvi-text-color) !important;

                path {
                  fill: var(--bvi-bg-color) !important;
                }
              }
            }
          }
        }
      }
    }

    &__plus {
      &:hover {
        .button {
          &__plus-wrap {
            transform: scale(1);
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  button, .button {
    &__header {
      padding: 0;

      .button {
        &__icon-wrap {
          display: flex;
        }
      }
    }
  }
}

@include respond-down('small') {
  button, .button {

  }
}