.index-slider {
  &__text-inner {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    transition: transform .8s, opacity .8s;
    transition-timing-function: var(--teaser-transition-func);

    @for $i from 2 through 100 {
      &:nth-child(#{$i}) {
        transition-delay: calc(((#{$i} - 1) * 0.1s));
      }
    }
  }

  &__index-slider {
    &.swiper {
      margin: 0 calc(0px - var(--grid-spacer-and-indent));
    }
  }

  &__arrows-wrap {
    position: absolute;
    left: 0;
    z-index: 100;
  }

  &__image-wrap {
    width: calc(var(--vw, 1vw) * 100);
  }

  &__index-slider-wrap {
    position: relative;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: calc(100vh - var(--header-height));
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: cover;

    transition: transform 1.5s var(--ref-cubic-func), opacity 1s var(--ref-cubic-func);
  }

  &__text-wrap {
    position: absolute;
    left: var(--grid-spacer-and-indent);
  }

  &__button {
    position: absolute;
    left: 0;
    z-index: 100;
  }

  &__item {

    &.swiper-slide-next,
    &.swiper-slide-prev {
      .index-slider {
        &__image {
          transform: scale(1.2);
          opacity: 0;
        }

        &__text-inner {
          opacity: 0;
          transform: translateY(100%);
        }
      }
    }

    &.swiper-slide-active {
      .index-slider {
        &__image {
          transform: scale(1);
          opacity: 1;
        }

        &__text-inner {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
}

@include respond-up('large') {
  .index-slider {
    &__text-wrap {
      width: var(--grid-column5);
    }
  }
}

@include respond-up('s-large') {
  .index-slider {
    --header-height: 78px;

    &__arrows-wrap {
      bottom: 80px;
    }

    &__text-wrap {
      bottom: 410px;
    }

    &__button {
      bottom: 315px;
    }
  }
}

@include respond('s-large') {
  .index-slider {
    &__text-wrap {
      width: var(--grid-column6);
    }
  }
}

@include respond-up('medium') {
  .index-slider {

  }
}

@include respond('medium') {
  .index-slider {
    --header-height: 62px;

    &__arrows-wrap {
      bottom: 65px;
    }

    &__text-wrap {
      width: var(--grid-column4);
      bottom: calc(278px);
    }

    &__button {
      bottom: 195px;
    }
  }
}

@include respond-down('medium') {
  .index-slider {

  }
}

@include respond-down('small') {
  .index-slider {
    --header-height: 58px;

    &__arrows-wrap {
      bottom: calc(90 / 720 * 100vh);
    }

    &__text-wrap {
      width: var(--grid-column4);
      bottom: calc(273 / 720 * 100vh);
    }

    &__button {
      bottom: calc(200 / 720 * 100vh);
    }
  }
}