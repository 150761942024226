.closest-events {

}

@include respond-up('s-large') {
  .closest-events {
    &__events-slider-wrap {
      margin-top: 35px;
    }

    &__item {
      &.swiper-slide {
        width: var(--grid-column12);
      }
    }

    &__button {
      margin-top: 50px;
    }
  }
}

@include respond-up('medium') {
  .closest-events {
    &__pagination {
      display: none;
    }

    &__list {
      &.swiper-wrapper {
        flex-direction: column;
      }
    }

    &__item {
      &:not(:first-child) {
        margin-top: var(--grid-gap);
      }
    }
  }
}

@include respond('medium') {
  .closest-events {
    &__events-slider-wrap {
      margin-top: 25px;
    }

    &__item {
      grid-column: span 6;
    }

    &__button {
      margin-top: 40px;
    }
  }
}

@include respond-down('medium') {
  .closest-events {

  }
}

@include respond-down('small') {
  .closest-events {
    &__events-slider-wrap {
      margin-top: 20px;
      position: relative;
    }

    &__item {
      &.swiper-slide {
        width: var(--grid-column4);
      }
    }

    &__events-slider {
      &.swiper {
        margin: 0 calc(0px - var(--grid-spacer-and-indent));
        padding-right: calc(var(--grid-spacer-and-indent) * 2);
      }
    }

    &__list {
      padding-left: var(--grid-spacer-and-indent);
    }

    &__item {
      &:not(:last-child) {
        margin-right: var(--grid-gap);
      }
    }

    &__button {
      margin-top: 53px;
    }

    .events {
      &__image-wrap {
        position: relative;
      }

      &__wrap {
        flex-direction: column-reverse;
      }
    }
  }
}