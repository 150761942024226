%text-breadcrumbs {
  font-family: var(--font);
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  text-transform: uppercase;
}

@include respond-up("s-large") {
  %text-breadcrumbs {
    --font-size: 12px;
  }
}

@include respond-down("medium") {
  %text-breadcrumbs {
    --font-size: 11px;
  }
}