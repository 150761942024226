.kids-sport {
  &__micro-title {
    position: relative;
  }

  &__description,
  &__link {
    margin-top: 25px;
  }

  &__info-blocks-wrap {
    &.grid-block {
      display: flex;
      flex-direction: column;
    }
  }

  &__contains-item {
    display: flex;
    align-items: center;
    column-gap: var(--grid-gap);

    &::before {
      content: '';
      flex: 0 0 10px;
      height: 10px;
      background: var(--bvi-text-color);
    }
  }

  &__contains-item,
  &__contains-text {
    padding: 0;
  }

  &__includes-button {
    margin-top: 25px;
  }
}

@include respond-up("s-large") {
  .kids-sport {
    &__about {
      display: flex;
    }

    &__includes {
      &.grid-block {
        display: flex;
        flex-direction: column;
      }
    }

    &__includes-title {
      order: 0;
    }

    &__contains {
      order: 1;
      display: flex;
      flex-direction: column;
      margin-top: 25px;
    }

    &__includes-button {
      order: 2;
    }
  }
}

@include respond-down("small") {
  .kids-sport {
    &__info-blocks-wrap {
      &.grid-block {
        row-gap: calc(var(--grid-gap) * 2);
      }
    }

    &__contains {
      grid-row-gap: calc(var(--grid-gap) * 2);
    }
  }
}