.breadcrumbs {
  &__name {
    display: flex;
  }

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    display: block;
  }

  &__item_delimiter {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__link,
  &__item_delimiter-icon {
    display: flex;
  }
}

@include respond-up("s-large") {
  .breadcrumbs {

    &__list {
      margin: -4px;
    }

    &__item {
      padding: 4px;
    }

    &__link {
      transition: color .4s;
      padding: 4px;
      margin: -4px;

      &:hover {
      }
    }
  }
}

@include respond("medium") {
  .breadcrumbs {

  }
}

@include respond-down("medium") {
  .breadcrumbs {
    &__list {
      margin: -3px;
    }

    &__item {
      padding: 3px;

      &:last-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__link {
      padding: 3px;
      margin: -3px;
    }
  }
}

@include respond-down("small") {
  .breadcrumbs {
    &__list {
      overflow-x: scroll;
      margin-right: calc(0px - var(--grid-spacer));
      margin-left: calc(0px - var(--grid-spacer));
      padding: 0 var(--grid-spacer);

      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__item {
      white-space: nowrap;

      &:last-child {
        padding-right: calc(var(--grid-spacer) * 2);
      }
    }
  }
}
