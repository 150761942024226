.micro-title {
  position: relative;
  pointer-events: none;
  font-family: var(--font);
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  text-transform: uppercase;

  &:before {
    content: '';
    position: absolute;
    background: var(--primary-red);
    top: 5px;
  }
}

@include respond-up('s-large') {
  .micro-title {
    padding-left: 25px;
    font-size: 16px;
    &:before {
      width: 10px;
      height: 10px;
      left: 0;
    }
  }
}

@include respond-down('medium') {
  .micro-title {
    padding-left: 20px;
    font-size: 14px;
    &:before {
      width: 8px;
      height: 8px;
      left: 0;
    }
  }
}