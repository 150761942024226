.drop-menu {
  &__item {
    .header__nav-link {
      display: flex;
    }
  }
}

@include respond-up('s-large') {
  .drop-menu {
    display: none;
  }
}

@include respond('medium') {
  .drop-menu {
    --header-height: 62px;

    top: 62px;
    padding-bottom: 174px;

    &__list {
      padding-top: 27px;
      padding-bottom: 50px;
    }

    &__item {
      .header__nav-link {
        padding-top: 7px;
        padding-bottom: 7px;

        &:first-child {
          padding-top: 14px;
          margin-top: -14px;
        }

        &:last-child {
          padding-bottom: 14px;
          margin-bottom: -14px;
        }
      }
    }

    &__children-list {
      padding-bottom: 25px;
    }

    &__recall-wrap {
      display: none;
    }
  }
}

@include respond-down('medium') {
  .drop-menu {
    position: absolute;
    opacity: 0;
    transform: translateY(-100%);
    pointer-events: none;
    background-color: white;
    transition-property: opacity, transform;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--teaser-transition-func);
    z-index: 4500;
    left: 0;
    right: 0;
    height: calc(100vh - var(--header-height));
    overflow: scroll;

    &._open {
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;
    }

    &__item {
      &._opened {
        .drop-menu {
          &__arrow {
            transform: rotate(180deg);
          }
        }
      }

      &:last-child {
        .drop-menu {
          &__nav-link {
            border-bottom: 1px solid var(--grey-line);
          }
        }
      }
    }

    &__nav-link {
      font-family: var(--font);
      font-size: 16px;
      font-weight: 500;
      line-height: 125%;
      padding: 12px 0;
      border-top: 1px solid var(--grey-line);
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 7px;
    }

    &__arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 15px;
      height: 11px;
      transition: transform var(--default-timing) var(--default-transition-function);

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__block {
      height: 0;
      transition: height var(--default-timing) var(--default-transition-function);
      overflow: hidden;
    }

    &__children-list {
      padding-top: 3px;
    }

    &__child-link {
      padding: 5px 0;
      display: flex;

      font-family: var(--font);
      font-size: 14px;
      font-weight: 400;
      line-height: 145%;
    }

    &__external-links {
      display: flex;
      margin: 0 -8px;
    }

    &__external-link {
      padding: 0 8px;
    }
  }
}

@include respond-down('small') {
  .drop-menu {
    --header-height: 58px;

    top: 58px;
    padding-bottom: 30px;

    &__list {
      padding-top: 22px;
      padding-bottom: 30px;
    }

    &__item {
      .header__nav-link {
        padding: 6px 0;

        &:first-child {
          padding-top: 12px;
          margin-top: -12px;
        }

        &:last-child {
          padding-bottom: 12px;
          margin-bottom: -12px;
        }
      }
    }

    &__children-list {
      padding-bottom: 15px;
    }

    &__nav-link {
      align-items: flex-start;
    }

    &__arrow {
      margin-top: 7px;
    }

    &__external-links {
      height: 50px;
    }

    &__recall-wrap {
      margin-top: 30px;
    }

    &__recall {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--light-blue);
      padding: 14px;
      column-gap: 10px;
      border-radius: 2px;
    }

    &__recall-icon-wrap,
    &__recall-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__recall-icon-wrap {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background: var(--primary-blue);
    }

    &__recall-icon {
      width: 10px;
      height: 10px;

      svg {
        width: 100%;
        height: 100%;

        path {
          fill: #fff;
        }
      }
    }

    &__recall-text {
      font-family: var(--font);
      font-size: 15px;
      font-weight: 500;
      line-height: 110%;
      letter-spacing: 0.02em;
      color: var(--primary-blue);
    }
  }
}