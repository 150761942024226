.form-block {
  &__wrap {
    position: relative;
  }

  &__image-wrap {
    width: calc(var(--vw, 1vw) * 100);
    margin-left: calc(0px - var(--grid-spacer-and-indent));
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-wrap {
    position: absolute;
    left: 0;
    width: var(--grid-column4);
  }

  &__form-wrap {
    background: white;
    border-radius: 4px;
    position: absolute;
  }
}

@include respond-up('s-large') {
  .form-block {
    padding-top: 230px;

    &__picture {
      height: calc(100vw * 534/1600);
    }

    &__text-wrap {
      top: 100px;
    }

    &__description {
      margin-top: 25px;
    }

    &__form-wrap {
      width: var(--grid-column6);
      right: 0;
      top: -60px;
      padding: 50px 60px;
    }
  }
}

@include respond('s-large') {
  .form-block {
    &__text-wrap {
      width: var(--grid-column5);
    }
  }
}

@include respond-up('medium') {
  .form-block {

  }
}

@include respond('medium') {
  .form-block {
    padding-top: 90px;
    padding-bottom: 203px;

    &__picture {
      height: calc(100vw * 489/768);
    }

    &__text-wrap {
      top: 40px;
    }

    &__description {
      margin-top: 18px;
    }

    &__form-wrap {
      width: var(--grid-column5);
      right: 0;
      bottom: -143px;
      padding: 50px;
    }
  }
}

@include respond-down('medium') {
  .form-block {

  }
}

@include respond-down('small') {
  .form-block {
    padding-top: 108px;
    padding-bottom: 250px;

    &__picture {
      height: calc(100vw * 356/375);
    }

    &__text-wrap {
      top: 30px;
    }

    &__description {
      margin-top: 8px;
    }

    &__form-wrap {
      width: calc(var(--grid-column4) + var(--grid-spacer-and-indent));
      right: calc(0px - var(--grid-spacer-and-indent));
      bottom: -220px;
      padding: 35px 25px;
    }
  }
}