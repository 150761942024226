.requisites {
  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    width: unset;
  }

  &__title {
    line-height: 100%;
  }
}