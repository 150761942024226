:root {
  --default-input-height: 37px;
  --errors-color: var(--primary-red)
}

$inputs: 'input[type="text"], input[type="search"], input[type="password"]';

.floating-field {
  &._filled {
    #{$inputs} {
      border-color: var(--primary-red);
    }
  }
}

form {

  #{$inputs}, textarea, select {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px white inset;
      -webkit-text-fill-color: black;
      transition: color .4s, text-fill-color .4s, box-shadow .4s !important;
      transition-timing-function: var(--default-transition-function);
    }
  }

  label {
    @extend %text-main;
  }

  ul.errors {
    list-style: none;
    color: var(--errors-color);
    font-size: 11px;
    text-transform: uppercase;
    line-height: 145%;
    font-weight: 400;

    li {
      margin-bottom: 2px;

      &:first-child {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          background: var(--primary-red);
          top: -1px;
          left: 0;
          height: 1px;
          width: 100%;
          z-index: 100;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-field {
    margin-bottom: 10px;
  }

  #{$inputs}, textarea, select {
    border: 0;
    border-bottom: 1px solid var(--grey-line);
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    width: 100%;
    display: block;
    appearance: none;
    font-family: var(--font);
  }

  #{$inputs} {
    transition: border-color var(--default-transition-function) var(--default-timing);

    &:focus {
      border-color: var(--primary-red);
    }
  }

  #{$inputs}, select {
    height: var(--default-input-height);
  }

  textarea {
    padding: 5px 10px;
  }

  //.button, button {
  //  padding: 0;
  //  margin: 0;
  //  text-align: center;
  //  transition: var(--default-transition);
  //  border: none;
  //  background: black;
  //  font-size: 16px;
  //  cursor: pointer;
  //  text-decoration: none;
  //  border-radius: 100px;
  //  color: #fff;
  //  font-weight: 500;
  //  line-height: 110%;
  //  text-transform: uppercase;
  //  font-stretch: 151%;
  //  font-variation-settings: var(--font-settings);
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //}
}

@include respond-up('s-large') {
  form {
    #{$inputs}, textarea, select, label {
      padding-bottom: 20px;
      height: 50px;
    }
  }
}

@include respond('medium') {
  form {
    #{$inputs}, textarea, select, label {
      padding-bottom: 20px;
    }
  }
}

@include respond-down('small') {
  form {
    #{$inputs}, textarea, select, label {
      padding-bottom: 15px;
    }
  }
}