.share-block {

}

@include respond-up('s-large') {
  .share-block {

    &__link{
      grid-column: 1/13;
      grid-row: 1;
    }

    &__share-wrap{
      grid-column: 1/13;
      grid-row: 2;
    }


  }
}

@include respond-up('medium') {
  .share-block {

  }
}

@include respond('medium') {
  .share-block {

    &__link{
      grid-column: 1/7;
      grid-row: 1;
    }

    &__share-wrap{
      grid-column: 1/7;
      grid-row: 2;
    }
  }
}

@include respond-down('medium') {
  .share-block {

  }
}

@include respond-down('small') {
  .share-block {
      &__share-wrap{
        flex-direction: column;
      }

    &__link{
      grid-column: 1/5;
    }
  }
}