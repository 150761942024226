.gallery-slider {
  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  &__slider-wrap {
    margin: 0 calc(0px - var(--grid-spacer-and-indent));
  }

  &__slider-list {
    &.swiper-wrapper {
      padding-left: var(--grid-spacer-and-indent);
    }
  }

  &__slider {
    &.swiper {
      padding-right: calc(var(--grid-spacer-and-indent) * 2);
    }
  }
}

@include respond-up('s-large') {
  .gallery-slider {

    &__picture {
      height: calc(var(--grid-column6) * 450/669);
    }

    &__slider-item {
      &.swiper-slide {
        width: var(--grid-column6);
      }
    }

    &__slider-wrap {
      margin-top: 35px;

      &:hover {
        cursor: none!important;
      }
    }
  }
}

@include respond-up('medium') {
  .gallery-slider {

  }
}

@include respond('medium') {
  .gallery-slider {
    &__picture {
      height: calc(var(--grid-column5) * 391/581);
    }

    &__slider-item {
      &.swiper-slide {
        width: var(--grid-column5);
      }
    }

    &__slider-wrap {
      margin-top: 25px;
    }
  }
}

@include respond-down('medium') {
  .gallery-slider {

  }
}

@include respond-down('small') {
  .gallery-slider {
    &__picture {
      height: calc((var(--grid-column4) - (var(--grid-column) / 3)) * 391/581);
    }

    &__slider-item {
      &.swiper-slide {
        width: calc(var(--grid-column4) - (var(--grid-column) / 3));
      }
    }

    &__slider-wrap {
      margin-top: 20px;
    }
  }
}