.slider-arrows {
  border-radius: 50%;
  transition: var(--default-transition);
  display: inline-block;
  text-align: center;

  &._white {
    background: white;
  }

  &._grey {
    background: var(--grey-background);
  }

  &._grey-opacity {
    background: rgba(255, 255, 255, 0.26);
    .slider-arrows {
      &__arrow {
        svg {
          line,
          path {
            stroke: white;
          }
        }
      }
    }
  }

  &_right {
    svg {
      transform: rotate(180deg);
    }
  }

  &__arrow {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

@include respond-up('s-large') {
  .slider-arrows {
    width: 52px;
    height: 52px;
    position: relative;

    &__arrow {
      overflow: hidden;

      svg {
        transition: transform var(--default-timing) var(--default-transition-function);
      }

      &_clone {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }

    &_left {
      .slider-arrows {
        &__arrow {
          &_clone {
            svg {
              transform: translateX(200%);
            }
          }
        }
      }

      &:hover {
        .slider-arrows {
          &__arrow {
            svg {
              transform: translateX(-200%);
            }
            &_clone {
              svg {
                transform: translateX(0);
              }
            }
          }
        }
      }
    }

    &_right {
      margin-left: 15px;

      .slider-arrows {
        &__arrow {
          &_clone {
            svg {
              transform: translateX(-200%) rotate(180deg);
            }
          }
        }
      }

      &:hover {
        .slider-arrows {
          &__arrow {
            svg {
              transform: translateX(200%) rotate(180deg);
            }
            &_clone {
              svg {
                transform: translateX(0) rotate(180deg);
              }
            }
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .slider-arrows {
    width: 40px;
    height: 40px;

    &_right {
      margin-left: 12px;
    }

    &__arrow {
      &_clone {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .slider-arrows {

  }
}
