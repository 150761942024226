%text-main {
  font-family: var(--font);
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 400;
  line-height: 145%;

  &._medium {
    font-weight: 500;
  }

  &._fifteen {
    --font-size: 15px;
  }
}

@include respond-up("medium") {
  %text-main {
    --font-size: 16px;
  }
}

@include respond-down("small") {
  %text-main {
    --font-size: 15px;
  }
}