.floating-field {
  &._filled input + label,
  input:focus + label {
    display: none;
  }

  label {
    top: 1px;
    left: 1px;
    bottom: 1px;
    height: unset;
  }
}