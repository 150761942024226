.uikit-page {
  padding-bottom: 100px;

  --black-ui-color: black;
  --blue-ui-color: blue;
  --background-ui-color: var(--primary-color);

  &__element_warning {
    margin-top: 50px;
    padding: 10px 10px 10px 50px;
    background-color: var(--background-ui-color);
    color: white;
    font-weight: 500;
    flex: 0 0 85%;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 34px 0 34px 34px;
      border-color: transparent transparent transparent  var(--background-color);
    }
  }

  &__elements-list {
    padding-top: 75px;
  }

  &__element {

    &:not(:first-child) {
      margin-top: 40px;
    }

    &._wide {
      flex: 0 0 100% !important;
    }

    &_dark {
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 10px;
      background-color: var(--blue-ui-color);

      .uikit-page__element-description {
        margin-right: -10px;
        margin-left: -10px;
      }
    }
  }

  &__element-description {
    margin-top: 20px;
    padding: 20px;
    background: var(--black-ui-color);
    color: white;
  }

  &__elements-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@include respond-up('s-large') {
  .uikit-page {
    &__element {
      flex: 0 0 48%;
    }
  }
}

@include respond('medium') {
  .uikit-page {

  }
}

@include respond-down('medium') {
  .uikit-page {
    &__element {
      flex: 0 0 100%;
    }
  }
}

@include respond-down('small') {
  .uikit-page {

  }
}