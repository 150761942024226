@font-face {
    src: url('https://fonts.googleapis.com/css2?family=Inter+Tight&display=swap');
    src: local('Inter Tight'),
    url('InterTight-Italic-VariableFont_wght.ttf') format('truetype'),
    url('InterTight-VariableFont_wght.ttf') format('truetype');
    font-family:'Roboto Flex';
    font-style: normal;
    font-weight: 100 1000;
    font-stretch: 1% 500%;
}


