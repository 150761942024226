.gallery {
  &__link {
    height: auto;
    display: inline-flex;
    //position: absolute; // change back in if you want masonry, but you will have to fix the first start: there is a bug
    //opacity: 0; // change back in if you want masonry, but you will have to fix the first start: there is a bug
    transition: opacity var(--default-timing) var(--default-transition-function);

    &._visible {
      opacity: 1;
    }
  }

  &__picture {
    height: auto;
    display: inline-flex;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up("s-large") {
  .gallery {
    padding-top: 70px;
    padding-bottom: 140px;

    &__link {
      grid-column: span 3;
    }

    &__picture {
      width: var(--grid-column3);
      height: calc(var(--grid-column3) * 216/324); // change back in if you want masonry, but you will have to fix the first start: there is a bug
    }
  }
}

@include respond("medium") {
  .gallery {
    padding-top: 40px;
    padding-bottom: 70px;
  }
}

@include respond-down("medium") {
  .gallery {
    &__picture {
      width: var(--grid-column2);
      height: calc(var(--grid-column2) * 216/324); // change back in if you want masonry, but you will have to fix the first start: there is a bug
    }

    &__link {
      grid-column: span 2;
    }
  }
}

@include respond-down("small") {
  .gallery {
    padding-top: 30px;
    padding-bottom: 50px;
  }
}