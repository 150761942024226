article {
  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    color: var(--black-text);

    &:not(:first-child) {
      margin-top: 60px;
    }
  }

  &, p, li {
    color: var(--grey-text);
    @extend %text-main;
  }

  li {
    padding-left: 21px;
  }

  ol {
    counter-reset: list;

    li {
      position: relative;

      &:before {
        counter-increment: list;
        content: counter(list) ".";
        position: absolute;
        left: 0;
        top: 3px;
        color: var(--primary-red);
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 100%;
      }
    }
  }

  ul {
    li {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 8px;
        width: 6px;
        height: 6px;
        background: var(--primary-red);
      }
    }
  }

  strong {
    font-weight: 500;
  }

  em {
    font-style: italic;
  }

  a {
    color: var(--primary-red);
    text-decoration: underline var(--t);
    transition: text-decoration-color var(--default-timing) var(--default-transition-function);

    &:hover {
      text-decoration-color: var(--primary-red);
    }
  }

  table {
    width: auto !important;

    &:not(:first-child) {
      margin-top: 25px;
    }

    td {
      &:not(:first-child) {
        padding-left: 25px;
      }
    }
  }
}

@include respond-up("large") {
  article {
  }
}

@include respond("large") {
  article {
  }
}

@include respond-down("large") {
  article {
  }
}

@include respond-up("s-large") {
  article {
    ul, ol, p {
      &:not(:first-child) {
        margin-top: 25px;
      }
    }
  }
}

@include respond("s-large") {
  article {
  }
}

@include respond-down("s-large") {
  article {
  }
}

@include respond-up("medium") {
  article {
    li {
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }
}

@include respond("medium") {
  article {
    ul, ol, p {
      margin-top: 20px;
    }
  }
}

@include respond-down("medium") {
  article {
  }
}

@include respond-down("small") {
  article {
    ul, ol, p {
      margin-top: 18px;
    }

    li {
      &:not(:first-child) {
        margin-top: 12px;
      }
    }
  }
}