.share {
  display: flex;
  align-items: center;

  .ya-share2__container_size_m .ya-share2__badge {
    background-color: white;
  }

  .ya-share2__container_size_m .ya-share2__badge .ya-share2__icon {
    height: 42px;
    width: 42px;
    background-size: 18px 18px !important;
    background-repeat: no-repeat;
    background-position: center;
  }

  .ya-share2__container_size_m {
    .ya-share2__item_service {
      &_vkontakte {
        .ya-share2__badge {
          .ya-share2__icon {
            background-image: url("../images/svg/icons/vk.svg");
          }
        }
      }

      &_odnoklassniki {
        .ya-share2__badge {
          .ya-share2__icon {
            background-image: url("../images/svg/icons/ok.svg");
          }
        }
      }
    }
  }

  .ya-share2__list.ya-share2__list_direction_horizontal .ya-share2__item {
    margin: 0;

    &:not(:first-child) {
      margin-left: 19px;
    }
  }

  .ya-share2 {
    &__list {
      .ya-share2 {
        &__item {
          &:hover {
            opacity: 1;

            .ya-share2 {
              &__link {
                .ya-share2 {
                  &__icon {
                    filter: invert(23%) sepia(89%) saturate(4381%) hue-rotate(352deg) brightness(85%) contrast(110%);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include respond-up("s-large") {
  .share {
    &__arrow-share {
      margin-right: 17px;
    }
  }
}


@include respond("medium") {
  .share {
    &__arrow-share {
      margin-right: 20px;
    }
  }
}

@include respond-down("small") {
  .share {
    &__arrow-share {
      margin-right: 18px;
    }
  }
}
