.index-kids-sport {
  grid-row-gap: 0;

  &__title {
    grid-area: title;
  }

  &__text {
    grid-area: kidstext;
  }

  &__description {
    grid-area: description;
  }

  &__picture {
    grid-area: picture;
  }

  &__info {
    grid-area: info;
  }

  &__link {
    grid-area: link;
  }

  &__value {
    grid-column: span 1;
  }

  &__info-line {
    display: grid;
    align-items: center;
    border-bottom: 1px solid var(--grey-line);
    grid-column-gap: var(--grid-gap);
  }

  &__info {
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--grey-line);
  }

  &__picture {
    display: flex;
    border-radius: 4px;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up("large") {
  .index-kids-sport {
  }
}

@include respond("large") {
  .index-kids-sport {
  }
}

@include respond-down("large") {
  .index-kids-sport {
  }
}

@include respond-up("s-large") {
  .index-kids-sport {
    grid-template-areas:
            "title title title title title title title title title title title title"
            "kidstext kidstext kidstext kidstext kidstext kidstext . description description description description description"
            "picture picture picture picture picture picture . info info info info info"
            "picture picture picture picture picture picture . link link link link link"
            "picture picture picture picture picture picture . . . . . ."
  ;

    &__info-line {
      padding: 20px 0;
    }

    &__text,
    &__description {
      margin-top: 35px;
    }

    &__link,
    &__info,
    &__picture {
      margin-top: 50px;
    }
  }
}

@include respond("s-large") {
  .index-kids-sport {
  }
}

@include respond-down("s-large") {
  .index-kids-sport {
  }
}

@include respond-up("medium") {
  .index-kids-sport {
    &__value-description {
      grid-column: span 4;
    }

    &__info-line {
      grid-template-columns: repeat(5, var(--grid-column1));
    }
  }
}

@include respond("medium") {
  .index-kids-sport {
    grid-template-areas:
            "title title title title title title "
            "kidstext kidstext kidstext kidstext kidstext . "
            "description description description description description ."
            "info info info info info ."
            "picture picture picture picture picture picture"
            "link link link link link link"
  ;

    &__info-line {
      padding: 18px 0;
    }

    &__description,
    &__text {
      margin-top: 20px;
    }

    &__link,
    &__info {
      margin-top: 40px;
    }

    &__picture {
      margin-top: 50px;
    }
  }
}

@include respond-down("medium") {
  .index-kids-sport {
  }
}

@include respond-down("small") {
  .index-kids-sport {
    grid-template-areas:
            "title title title title"
            "kidstext kidstext kidstext kidstext"
            "picture picture picture picture"
            "description description description description"
            "info info info info"
            "link link link link"
  ;

    &__info-line {
      padding: 16px 0;
    }

    &__text {
      margin-top: 20px;
    }

    &__link,
    &__info,
    &__picture,
    &__description {
      margin-top: 30px;
    }

    &__value-description {
      grid-column: span 3;
    }

    &__info-line {
      grid-template-columns: repeat(4, var(--grid-column1));
    }
  }
}