.share-block {

  &__share-wrap {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--grey-line);
  }
}

@include respond-up('s-large') {
  .share-block {
    &__link {
      grid-column: 1/4;
    }

    &__share-wrap {
      grid-column: 6/13;
      padding-top: 22px;
    }
  }
}

@include respond-up('medium') {
  .share-block {

  }
}

@include respond('medium') {
  .share-block {
    &__link {
      grid-column: 1/3;
    }

    &__share-wrap {
      grid-column: 4/7;
    }
  }
}

@include respond-down('medium') {
  .share-block {
    &__share-wrap {
      padding-top: 20px;
    }
  }
}

@include respond-down('small') {
  .share-block {
    &__link {
      grid-column: 1/4;
      grid-row: 2;
      margin-top: 32px;
    }

    &__share-wrap {
      grid-column: 1/5;
      grid-row: 1;
    }
  }
}