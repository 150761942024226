.hamburger {

}

.bvi-body[data-bvi-theme=white],
.bvi-body[data-bvi-theme=black],
.bvi-body[data-bvi-theme=blue],
.bvi-body[data-bvi-theme=brown],
.bvi-body[data-bvi-theme=green] {
  .hamburger__menu-lines{
    &:before {
      background: var(--bvi-text-color)!important;
    }
    &:after {
      background: var(--bvi-text-color)!important;
    }
    &:hover{
      background: var(--bvi-bg-color)!important;
    }
  }
}


@include respond-up('large') {
  .hamburger {

  }
}

@include respond-up('medium') {
  .hamburger {

  }
}

@include respond('medium') {
  .hamburger {

  }
}

@include respond-down('medium') {
  .hamburger {

  }
}

@include respond-down('small') {
  .hamburger {

  }
}