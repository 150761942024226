.block {
  &__top120 {
    padding-top: var(--top120);
  }

  &__top80 {
    padding-top: var(--top080);
  }

  &__top60 {
    padding-top: var(--top060);
  }

  &__top50 {
    padding-top: var(--top050);
  }

  &__white {
    background: white;
    overflow: hidden;
  }
}

@include respond-up('s-large') {
  .block {
    --top120: 120px;
    --top080: 80px;
    --top060: 60px;
    --top050: 50px;
  }
}

@include respond('medium') {
  .block {
    --top120: 90px;
    --top080: 60px;
    --top060: 50px;
    --top050: 40px;
  }
}

@include respond-down('small') {
  .block {
    --top120: 50px;
    --top080: 30px;
    --top060: 40px;
    --top050: 30px;
  }
}