.social-icons {
  border-radius: 50%;
  text-align: center;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

  &._grey {
    background: var(--grey-background);
  }

  &._white {
    background: white;
  }

  &_tg {
    margin-left: 19px;
    svg {
      transform: translateY(3px);
    }
  }

  &__wrap {
    display: flex;
  }

  &__icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
  }
}

@include respond-up('s-large') {
  .social-icons {
    svg {
      path {
        transition: fill var(--default-timing) var(--default-transition-function);
      }
    }

    &:hover {
      svg {
        path {
          fill: var(--primary-red);
        }
      }
    }
  }
}