.team-slider {
  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  &__description {
    margin-top: 12px;
  }
}

@include respond-up('s-large') {
  .team-slider {
    margin-top: 25px;

    &__picture {
      height: calc(var(--grid-column3) * 411/324);
    }

    &__slider-item {
      width: var(--grid-column3);
    }

    &__info-wrap {
      margin-top: 28px;
    }
  }
}

@include respond-up('medium') {
  .team-slider {

  }
}

@include respond('medium') {
  .team-slider {
    &__picture {
      height: calc(var(--grid-column2) * 282/224);
    }

    &__slider-item {
      width: var(--grid-column2);
    }

    &__info-wrap {
      margin-top: 24px;
    }
  }
}

@include respond-down('medium') {
  .team-slider {
    margin-top: 22px;
  }
}

@include respond-down('small') {
  .team-slider {

    &__picture {
      height: calc(var(--grid-column3) * 317/250);
    }

    &__slider-item {
      width: var(--grid-column3);
    }

    &__info-wrap {
      margin-top: 22px;
    }

    &__slider-wrap {
      margin-right: calc(0px - var(--grid-spacer-and-indent));
    }

    &__slider {
      &.swiper {
        margin-left: calc(0px - var(--grid-spacer-and-indent));
        padding: 0 var(--grid-spacer-and-indent);
      }
    }
  }
}