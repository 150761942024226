.event {

}

@include respond-up('s-large') {
  .event {
    &__sidebar {
      grid-column: 1 / 13;
    }

    &__content {
      grid-column: 1 / 13;
    }
  }
}

@include respond-up('medium') {
  .event {

  }
}

@include respond('medium') {
  .event {
    &__sidebar {
      grid-column: 1 / 7;
    }

    &__content {
      grid-column: 1 / 7;
    }
  }
}

@include respond-down('medium') {
  .event {

    &__sidebar{
      margin-top: 0;
    }
  }
}

@include respond-down('small') {
  .event {
    &__sidebar-line{
      flex-direction: column;
      &:not(:last-child) {
        border-bottom: 1px solid var(--bvi-text-color);
      }
    }

    &__participate{
      flex-direction: column;
      align-items: flex-start;
    }
  }
}