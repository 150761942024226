.service {
  grid-row-gap: 0;

  &__another-item {
    &.swiper-slide {
      height: auto;
    }
  }

  &__enroll {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__pictures {
    display: flex;
    justify-content: space-between;
  }

  &__picture {
    display: flex;
    border-radius: 4px;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__sidebar,
  &__sidebar-part {
    display: flex;
    flex-direction: column;
  }

  &__sidebar-line {
    display: flex;
    justify-content: space-between;
  }

  &__sidebar-line {
    &:not(:first-child) {
      padding-top: 10px;
    }
  }

  &__sidebar-part {
    border-bottom: 1px solid var(--grey-line);

    article {
      h1, h2, h3, h4, h5, h6 {
        @extend %text-main;
        color: var(--grey-text);
      }

      p, li {
        &:not(:first-child) {
          margin-top: 10px;
        }
      }

      li {
        padding-left: 0;

        &:before {
          display: none;
        }
      }
    }
  }

  &__info-blocks-item {
    //&:not(:last-child) {
    background: white;

    .service {
      &__info-block-count {
        border: 1px solid var(--grey-line);
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 41px;
        height: 41px;
      }
    }

    //}

    //&:last-child {
    //  background: var(--primary-blue);
    //
    //  .service {
    //    &__info-block-title,
    //    &__info-block-description {
    //      color: white;
    //    }
    //  }
    //}
  }

  &__info-block-description {
    margin-top: 10px;
  }
}

@include respond-up("s-large") {
  .service {
    padding-top: 70px;
    padding-bottom: 120px;

    &__info-blocks-wrap {
      grid-column: 1/13;
      margin-top: 120px;
    }

    &__info-blocks-list {
      margin-top: 36px;
    }

    &__info-blocks-item {
      grid-column: span 3;
      padding: 30px;

      //&:not(:last-child) {
      .service {
        &__info-block-title {
          margin-top: 35px;
        }
      }

      //}

      //&:last-child {
      //  .service {
      //    &__info-block-title {
      //      margin-top: 27px;
      //    }
      //  }
      //}
    }

    &__picture-first {
      width: var(--grid-column4);
      height: calc(319 / 439 * var(--grid-column4));
    }

    &__picture-second {
      width: var(--grid-column3);
      height: calc(235 / 323 * var(--grid-column3));
    }

    &__main-picture {
      width: 100%;
      height: calc(475 / 783 * var(--grid-column7));
    }

    &__sidebar-part {
      padding-bottom: 30px;
      margin-bottom: 30px;
    }

    &__sidebar {
      grid-column: 1/5;
    }

    &__main-picture,
    &__content {
      grid-column: 6/13;
    }

    &__slider-wrap {
      grid-column: 1/13;
    }

    &__share-block {
      grid-column: 1/13;
      margin-top: 80px;
    }

    &__another-services {
      grid-column: 1/13;
      margin-top: 100px;
    }

    &__another-slider-wrap {
      margin-top: 35px;
    }

    &__another-item {
      &.swiper-slide {
        width: var(--grid-column6);
      }

      //&:not(:first-child) {
      //  margin-left: var(--grid-gap);
      //}
    }
  }
}

@include respond-up("medium") {
  .service {
    &__slider-wrap {
      margin-top: 120px;
    }

    &__pagination {
      display: none;
    }
  }
}

@include respond("medium") {
  .service {
    padding-top: 40px;
    padding-bottom: 90px;

    &__info-blocks-wrap {
      grid-column: 1/7;
      margin-top: 90px;
    }

    &__info-blocks-list {
      margin-top: 25px;
    }

    &__info-blocks-item {
      grid-column: span 3;
      padding: 25px;

      //&:not(:last-child) {
      .service {
        &__info-block-title {
          margin-top: 30px;
        }
      }

      //}

      //&:last-child {
      //  .service {
      //    &__info-block-title {
      //      margin-top: 22px;
      //    }
      //  }
      //}
    }

    &__picture-first {
      width: var(--grid-column3);
      height: calc(249 / 343 * var(--grid-column3));
    }

    &__picture-second {
      width: var(--grid-column2);
      height: calc(163 / 224 * var(--grid-column2));
    }

    &__main-picture {
      width: 100%;
      height: calc(424 / 700 * var(--grid-column6));
    }

    &__content,
    &__sidebar {
      grid-column: 2/7;
    }

    &__sidebar {
      margin-top: 50px;
    }

    &__main-picture,
    &__slider-wrap,
    &__share-block {
      grid-column: 1/7;
    }

    &__share-block {
      margin-top: 70px;
    }

    &__another-services {
      grid-column: 1/7;
      margin-top: 76px;
    }

    &__another-slider-wrap {
      margin-top: 25px;
    }

    &__another-item {
      //&:not(:first-child) {
      //  margin-left: var(--grid-gap);
      //}

      &.swiper-slide {
        width: var(--grid-column3);
      }
    }
  }
}

@include respond-down("medium") {
  .service {
    &__main-picture {
      order: 1;
    }

    &__sidebar {
      order: 2;
    }

    &__content {
      order: 3;
    }

    &__slider-wrap {
      order: 6;
    }

    &__info-blocks-wrap {
      order: 7;
    }

    &__share-block {
      order: 8;
    }

    &__another-services {
      order: 9;
    }

    &__sidebar-part {
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
  }
}

@include respond-down("small") {
  .service {
    padding-top: 20px;
    padding-bottom: 50px;

    &__info-blocks-wrap {
      grid-column: 1/5;
      margin-top: 40px;
    }

    &__info-blocks-list {
      margin-top: 20px;
    }

    &__info-blocks-item {
      grid-column: span 4;
      padding: 25px;

      //&:not(:last-child) {
      .service {
        &__info-block-title {
          margin-top: 25px;
        }
      }

      //}

      //&:last-child {
      //  .service {
      //    &__info-block-title {
      //      margin-top: 17px;
      //    }
      //  }
      //}
    }

    &__picture-first,
    &__picture-second {
      width: var(--grid-column2);
      height: calc(116 / 160 * var(--grid-column2));
    }

    &__main-picture {
      width: 100%;
      height: calc(204 / 338 * var(--grid-column4));
    }

    &__main-picture,
    &__sidebar,
    &__content,
    &__slider-wrap,
    &__share-block {
      grid-column: 1/5;
    }

    &__sidebar {
      margin-top: 30px;
    }

    &__slider-wrap {
      margin-top: 40px;
    }

    &__share-block {
      margin-top: 40px;
      grid-row-gap: 0;
    }

    &__another-services {
      grid-column: 1/5;
      margin-top: 36px;
    }

    &__another-slider-wrap {
      position: relative;
      margin-top: 18px;
    }

    &__another-slider {
      &.swiper {
        margin: 0 calc(0px - var(--grid-spacer-and-indent));
        padding-right: calc(var(--grid-spacer-and-indent) * 2);
      }
    }

    &__another-list {
      padding-left: var(--grid-spacer-and-indent);
    }
  }
}