.index-kids-sport {
  &.grid-block {
    display: flex;
    flex-direction: column;
  }

  &__info-line {
    display: flex;
    column-gap: var(--grid-gap);
  }

  &__value {
    &.text__large-and-red {
      font-weight: 400;
    }

    flex: 0 0 var(--grid-column1);
  }
}

@include respond-down("small") {
  .index-kids-sport {
    &__button {
      &.button {
        width: 100%;
        white-space: normal;
      }
    }
  }
}