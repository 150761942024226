.cursor {
  display: block;
  height: 50px;
  width: 50px;
  margin-left: -15px;
  margin-top: -15px;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 300000;


  &__drag {
    position: absolute;
    left: -45px;
    top: -45px;
    opacity: 0;
    transform: scale(.1);
    transition: {
      property: opacity, transform;
      duration: .0s, .3s;
      delay: .3s, 0s;
      timing-function: linear;
    };
  }

  &._drag {
    .cursor {
      &__disc {
        opacity: 0;
        transition-duration: 0s;
      }

      &__drag {
        opacity: 1;
        transform: scale(1);
        transition: {
          delay: 0s;
        };
      }
    }
  }

  &._hide {
    display: none;
  }
}

@include respond-up('large') {
  .cursor {

  }
}

@include respond-up('medium') {
  .cursor {

  }
}

@include respond('medium') {
  .cursor {

  }
}

@include respond-down('medium') {
  .cursor {

  }
}

@include respond-down('small') {
  .cursor {

  }
}