.info-block-index {
  padding: 0;
  flex-direction: row;
  align-items: baseline;
  column-gap: var(--grid-gap);

  &::before {
    flex: 0 0 10px;
    height: 10px;
    background: var(--bvi-text-color);
    position: static;
  }

  &::after {
    content: unset;
  }

  &__name,
  &__text {
    padding: 0;
  }
}

@include respond-up('s-large') {
  .info-block-index {
    grid-column: span 12;
  }
}