.content-header {
  &__title {
    margin-top: 20px;
  }

  &__label{
    left: 0;
    bottom: 30px;
    padding-left: 0;
  }
}

@include respond-up('s-large') {
  .content-header {

    &__wrap{
      row-gap: 30px;
    }

    &__date-wrap {
      padding-top: 0;
    }

    &__info-wrap{
      grid-row: 1;
    }

    &__price-text{
      grid-column: 1/13;
    }

    &__title {
      margin-top: 30px;
    }
  }
}

@include respond-up('medium') {
  .content-header {

  }
}

@include respond('medium') {
  .content-header {
    &__wrap{
      padding: 40px 0 110px;
      row-gap: 30px;
    }

    &__price-text{
      grid-column: 1/7;
    }
  }
}

@include respond-down('medium') {
  .content-header {
    margin-bottom: 0;
  }
}

@include respond-down('small') {
  .content-header {
    &__wrap {
      padding: 30px 0 100px;
      &::after {
        content: none;
      }
    }

    &__price-text{
      grid-column: 1/5;
    }
  }
}