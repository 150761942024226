.tabs {
  //overflow-x: scroll;
  //margin: 0 calc(var(--grid-spacer) * -1);

  &::-webkit-scrollbar {
    display: none;
  }

  &__text {
    white-space: nowrap;
    font-family: var(--font);
    font-style: normal;
    line-height: 125%;
    font-weight: 400;
    text-transform: uppercase;
  }

  &__list {
    display: flex;
    position: relative;
    justify-content: flex-start;
    list-style: none;

    &:before {
      content: '';
      position: absolute;
      background: var(--grey-line);
      bottom: 0;
      left: calc(0px - var(--grid-spacer-and-indent));
      width: 100vw;
      height: 1px;
    }
  }

  &__link {
    position: relative;
    display: flex;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 15px;
      background: var(--primary-red);
      height: 2px;
      width: calc(100% - 30px);
      opacity: 0;
      transition: opacity var(--default-timing) var(--default-transition-function);
    }
  }

  &__item {
    margin: 0 15px;
    &._active {
      .tabs__link {
        &:before {
          opacity: 1;
        }
      }
    }
    &:first-child {

      .tabs__link {
        margin-left: -30px;
      }
    }

    &:last-child {

      .tabs__link {
        margin-right: -30px;
      }
    }
  }
}

@include respond-up('s-large') {
  .tabs {

    &__text {
      transition: color var(--default-timing) var(--default-transition-function);
      font-size: 15px;
    }

    &__link {
      padding: 20px 15px;
      margin: -20px -15px 0 -15px;
    }

    &__item {
      &:hover {
        .tabs {
          &__text {
            color: var(--primary-red);
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .tabs {
    &__text {
      font-size: 13px;
    }
    &__link {
      padding: 18px 15px;
      margin: -18px -15px 0 -15px;
    }
  }
}