:root {

}

$inputs: 'input[type="text"], input[type="search"], input[type="password"]';

form {
  ul.errors {
    li {
      margin-bottom: 2px;

      &:first-child {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          background: var(--primary-red);
          top: -1px;
          left: 0;
          height: 1px;
          width: 100%;
          z-index: 100;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  #{$inputs}, textarea, select {
    border: 1px solid var(--grey-line);
  }

  #{$inputs}, textarea, select, label {
    height: unset;
  }
}

@include respond-up('s-large') {
  form {
    #{$inputs}, textarea, select, label {
      padding: 20px;
    }

    #{$inputs}, textarea, select {
      min-height: 50px;
    }
  }
}

@include respond('medium') {
  form {
    #{$inputs}, textarea, select, label {
      padding: 20px;
    }

    #{$inputs}, textarea, select {
      min-height: var(--default-input-height);
    }
  }
}

@include respond-down('small') {
  form {
    #{$inputs}, textarea, select, label {
      padding: 15px;
    }
  }
}