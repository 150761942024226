.contacts {
  &__phone {
    white-space: nowrap;
  }

  &__top-info {
    grid-row-gap: 0;
  }

  &__title {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    font-size: var(--size);
  }

  &__socials {
    .social-icons {
      &_tg {
        order: 1;
        margin-left: 0;
      }

      &_vk {
        order: 2;
        margin-left: 16px;
      }
    }
  }

  &__item {
    &:nth-child(3) {
      .contacts {
        &__value {
          display: inline-block;
        }
      }
    }
  }

  &__schedule-wrap {
    display: grid;
    grid-template-columns: auto 1fr;

    &:not(:first-child) {
      margin-top: 6px;
    }
  }

  &__map-block {
    margin: 0 calc(0px - var(--grid-spacer-and-indent));
  }
}

@include respond-up('s-large') {
  .contacts {
    padding-top: 45px;
    padding-bottom: 140px;

    &__phone-wrap {
      grid-column: 1/4;
    }

    &__info-list {
      grid-column: 7/13;
    }

    &__button-wrap {
      margin-top: 35px;
    }

    &__time {
      margin-left: 23px;
    }

    &__map-block {
      margin-top: 100px;
    }
  }
}

@include respond-up('medium') {
  .contacts {
    --size: 14px;

    &__item {
      &:not(:nth-child(3)) {
        .contacts {
          &__value {
            margin-top: 18px;
          }
        }
      }

      &:nth-child(3) {
        .contacts {
          &__value {
            padding: 18px;
            margin: 0 -18px;
          }
        }
      }
    }

    &__info-list {
      display: grid;
      grid-row-gap: 60px;
      grid-column-gap: var(--grid-gap);
      grid-template-areas:
        "address schedule"
        "email socials";
    }

    &__item {
      width: var(--grid-column3);

      &_address {
        grid-area: address;
      }

      &_schedule {
        grid-area: schedule;
      }

      &_email {
        grid-area: email;
      }

      &_socials {
        grid-area: socials;
      }
    }

    &__map-block {
      height: 650px;
    }
  }
}

@include respond('medium') {
  .contacts {
    padding-top: 40px;
    padding-bottom: 90px;

    &__phone-wrap {
      grid-column: 1/3;
      grid-row: 1;
    }

    &__info-list {
      grid-column: 1/7;
      grid-row: 2;
      padding-top: 50px;
      margin-top: 30px;
    }

    &__button-wrap {
      margin-top: 20px;
    }

    &__time {
      margin-left: 23px;
    }

    &__map-block {
      margin-top: 76px;
    }
  }
}

@include respond-down('medium') {
  .contacts {
    &__info-list {
      border-top: 1px solid var(--grey-line);
    }
  }
}

@include respond-down('small') {
  .contacts {
    --size: 13px;
    padding-top: 30px;
    padding-bottom: 50px;

    &__phone-wrap {
      grid-column: 1/5;
      grid-row: 1;
    }

    &__info-list {
      grid-column: 1/5;
      grid-row: 2;
      display: flex;
      flex-direction: column;
      padding-top: 35px;
      margin-top: 20px;
    }

    &__item {
      &:not(:first-child) {
        margin-top: 40px;
      }

      &:not(:nth-child(3)) {
        .contacts {
          &__value {
            margin-top: 16px;
          }
        }
      }

      &:nth-child(3) {
        .contacts {
          &__value {
            padding: 16px;
            margin: 0 -16px;
          }
        }
      }
    }

    &__button-wrap {
      margin-top: 18px;
    }

    &__time {
      margin-left: 26px;
    }

    &__map-block {
      margin-top: 40px;
    }

    &__map-block {
      height: 450px;
    }
  }
}