.services {

}

@include respond-up('s-large') {
  .services {
    padding: 70px 0 80px 0;

    &__list {
      grid-row-gap: 40px;
    }

    &__item {
      &_category {
        grid-column: span 6;
      }

      &_service {
        grid-column: span 4;
      }
    }
  }
}

@include respond('medium') {
  .services {
    padding: 40px 0 60px 0;

    &__list {
      grid-row-gap: 30px;
    }

    &__item {
      grid-column: span 3;
    }
  }
}

@include respond-down('small') {
  .services {
    padding: 20px 0 30px 0;

    &__list {
      grid-row-gap: 14px;
    }

    &__item {
      grid-column: span 4;
    }
  }
}