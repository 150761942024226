.request-form-success {

  &__title {
    margin-top: 30px;
  }
}

@include respond-up('medium') {
  .request-form-success {
    &__subtitle {
      margin-top: 12px;
    }
  }
}

@include respond-down('small') {
  .request-form-success {
    &__subtitle {
      margin-top: 10px;
      text-align: center;
    }

    &__icon {
      svg {
        width: 68px;
        height: 68px;
      }
    }
  }
}