.image-block {
  display: flex;
  position: relative;
  background: var(--grey-background);
  border-radius: 4px;

  &__text {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    font-size: var(--size);
  }

  &__badges {
    position: absolute;
  }
}

@include respond-up('s-large') {
  .image-block {
    margin-top: 120px;
    height: 269px;
    --size: 24px;

    &__right-part-large {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
    }

    &__left-part-large {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 100%;
    }

    &__text {
      width: var(--grid-column4);
      margin-left: calc(var(--grid-column) + var(--grid-gap));
    }

    &__badges {
      right: var(--grid-column);
    }

    &__right-part-tab {
      display: none;
    }
  }
}

@include respond-up('medium') {
  .image-block {
    &__badges {
      top: 50%;
      transform: translateY(-50%);
    }

    &__text {
      display: flex;
      align-items: center;
    }

    &__bottom-part-mob,
    &__right-part-mob {
      display: none;
    }
  }
}

@include respond('medium') {
  .image-block {
    margin-top: 70px;
    height: 209px;
    --size: 18px;

    &__right-part-tab {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
    }

    &__text {
      width: calc(var(--grid-column2) + var(--grid-gap));
      margin-left: 35px;
    }

    &__badges {
      right: 48px;

      svg {
        width: 165px;
        height: 127px;
      }
    }
  }
}

@include respond-down('medium') {
  .image-block {
    &__left-part-large,
    &__right-part-large {
      display: none;
    }
  }
}

@include respond-down('small') {

}

//small
@media (max-width: 579px) {
  .image-block {
    margin-top: 50px;
    padding: 30px 25px;
    height: 525px;
    --size: 19px;
    overflow: hidden;

    &__right-part-tab,
    &__right-part-mob {
      display: none;
    }

    &__text {
      width: 288px;
    }

    &__badges {
      left: 50%;
      transform: translateX(-50%);
      bottom: 30px;

      svg {
        width: 210px;
        height: 164px;
      }
    }

    &__bottom-part-mob {
      position: absolute;
      right: 0;
      bottom: calc(1px + (100vw - 579px) / 4);
      left: calc(-106px + (100vw - 375px) / 2);

      svg {
        width: 600px;
        height: auto;
      }
    }
  }
}

//medium
@media (min-width: 581px) and (max-width: 1199px) {
  .image-block {

  }
}

@media (min-width: 580px) and (max-width: 767px) {
  .image-block {
    margin-top: 50px;
    height: 209px;
    padding: 0;
    --size: 19px;

    &__bottom-part-mob,
    &__right-part-tab {
      display: none;
    }

    &__right-part-mob {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
    }

    &__text {
      display: flex;
      align-items: center;
      width: calc(var(--grid-column) * 1.5);
      z-index: 100;
      margin-left: var(--grid-spacer-and-indent);
    }

    &__badges {
      top: 50%;
      transform: translateY(-50%);
      right: var(--grid-spacer-and-indent);

      svg {
        width: 165px;
        height: 127px;
      }
    }
  }
}