.article-slider {
  position: relative;

  .slider-arrows {
    &_right {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-60%, -50%);
    }

    &_left {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(60%, -50%);
    }
  }

  &__nav {
    position: relative;
  }

  &__nav-wrap {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    width: 100%;
  }

  &__picture {
    display: flex;
    border-radius: 4px;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up("large") {
  .article-slider {
  }
}

@include respond("large") {
  .article-slider {
  }
}

@include respond-down("large") {
  .article-slider {
  }
}

@include respond-up("s-large") {
  .article-slider {
  }
}

@include respond("s-large") {
  .article-slider {
  }
}

@include respond-down("s-large") {
  .article-slider {
  }
}

@include respond-up("medium") {
  .article-slider {
  }
}

@include respond("medium") {
  .article-slider {
  }
}

@include respond-down("medium") {
  .article-slider {
  }
}

@include respond-down("small") {
  .article-slider {
  }
}