.index-rslider {
  &__text-list {
    display: flex;
    flex-direction: column;
    row-gap: calc(var(--grid-gap) * 2);
    padding-bottom: 25px;
  }

  &__text-item {
    position: static;
  }

  &__text-inner {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &__button {
    position: static;

    .button {
      width: 100%;
      white-space: normal;
    }
  }
}

@include respond-up('s-large') {
  .index-rslider {
    &__text-list {
      padding-top: 80px;
    }

    &__text-item {
      width: var(--grid-column12);
    }
  }
}

@include respond('medium') {
  .index-rslider {
    &__text-list {
      padding-top: 50px;
    }

    &__text-item {
      width: var(--grid-column6);
    }
  }
}

@include respond-down('small') {
  .index-rslider {
    &__text-list {
      padding-top: 30px;
    }

    &__text-item {
      width: var(--grid-column4);
    }
  }
}