body {
  .bvi-show {
    display: none;
  }

  .bvi-body[data-bvi-theme=white] {
    --bvi-bg-color: #fff;
    --bvi-text-color: #000;
  }
  .bvi-body[data-bvi-theme=black] {
    --bvi-bg-color: #000;
    --bvi-text-color: #fff;
  }
  .bvi-body[data-bvi-theme=blue] {
    --bvi-bg-color: #9DD1FF;
    --bvi-text-color: #063462;
  }
  .bvi-body[data-bvi-theme=brown] {
    --bvi-bg-color: #F7F3D6;
    --bvi-text-color: #4D4B43;
  }
  .bvi-body[data-bvi-theme=green] {
    --bvi-bg-color: #3B2716;
    --bvi-text-color: #A9E44D;
  }

  &.bvi-active {
    .swiper-wrapper {
      transform: unset !important;
    }

    .bvi-link {
      &.bvi-link-fixed-top {
        right: var(--grid-spacer-and-indent) !important;
        top: calc(var(--header-height-val) + 20px) !important;
      }
    }


    .bvi-hide-bg-image {
      background-image: unset !important;
      background-size: unset;
      background-repeat: unset;
      background-position: unset;
    }

    * {
      transition: unset !important;
    }

    .bvi-body {
      .bvi-underline-link {
        text-decoration: underline dashed;
        text-underline-offset: 5px;
      }

      .bvi-word-wrap {
        overflow-wrap: break-word;
        word-break: break-word;
        white-space: normal;
      }

      *:not(.bvi-no-styles) {
        a, button {
          span, div {
            color: inherit !important;
            background-color: inherit !important;

            &::before,
            &::after {
              background-color: transparent !important;
            }
          }
        }
      }
    }

    @import "button";
    @import "drop-menu";
    @import "hamburger";
    @import "event";
    @import "header";
    @import "tabs";
    @import "social-icons";
    @import "form-block";
    @import "index-form";
    @import "footer";
    @import "floating-field";
    @import "forms";
    @import "policy-checkbox";
    @import "modal-form";
    @import "weapons-modal";
    @import "requisites";
    @import "contacts";
    @import "breadcrumbs";
    @import "bvi-settings";
    @import "content-header";
    @import "events";
    @import "services";
    @import "service-card";
    @import "micro-title";
    @import "kids-sport";
    @import "info-block-kids";
    @import "advantages";
    @import "team-slider";
    @import "image-block";
    @import "closest-events";
    @import "weapons";
    @import "charter-docs";
    @import "info-block-index";
    @import "about";
    @import "service";
    @import "index-kids-sport";
    @import "partners-slider";
    @import "index-contacts";
    @import "index-rslider";
    @import "share-block";
    @import "ya-share2";
    @import "article";
  }
}