.header {
  width: 100%;
  z-index: 4700;
  background: white;

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__phone {
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    white-space: nowrap;
  }

  &__container-wrap {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 100%;
      background: var(--grey-line);
      z-index: 4610;
      pointer-events: none;
    }
  }

  &__logo {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__phone-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__part {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__external-link-text{
    display: none;
  }
}

@include respond-up('s-large') {
  .header {
    &__phone-wrap {
      margin-left: calc(94 / 1600 * 100vw);
    }

    &__version {
      margin-right: calc(54 / 1600 * 100vw);
    }

    &__version-text {
      margin-left: 12px;
      font-family: var(--font);
      font-size: 15px;
      font-weight: 500;
      line-height: 21.75px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: var(--black-text);
    }

    &__external-links {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: -8px;
      margin-left: -8px;
    }

    &__external-link {
      padding-left: 8px;
      padding-right: 8px;
    }

    &__nav-list-wrapper {
      height: inherit;
      display: flex;
      align-items: center;
    }

    &__phone {
      font-size: 15px;
    }

    &__wrap {
      height: 74px;

      &_lower {
        height: 67px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: calc(-1 * var(--grid-spacer-and-indent));
          right: calc(-1 * var(--grid-spacer-and-indent));
          border-top: 1px solid var(--grey-line);
        }
      }
    }

    &__nav-list {
      height: inherit;
      display: flex;
      align-items: center;
    }

    &__nav-item {
      height: 100%;

      &_services {
        &:hover {
          .nav-subitem {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }
    }

    &__nav-link {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 15px;

      svg {
        margin-left: 7px;
        transition: transform .4s var(--default-transition-function);
      }

      &._normal {
        svg {
          display: none;
        }
      }

      &:hover {
        svg {
          transform: rotate(180deg);
        }

        .header {
          &__link-text {
            &:before {
              transform-origin: left;
              transform: scaleX(1);
              transition: transform .4s var(--default-transition-function);
            }
          }
        }
      }
    }

    &__link-text {
      position: relative;
      white-space: nowrap;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: -3px;
        width: 100%;
        height: 1px;
        background: var(--black-text);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform .4s var(--default-transition-function);
      }
    }

    &__button-wrap {
      margin-left: 30px;
    }

    &__nav-list-wrapper {
      margin: 0 clamp(-15px, calc(-1 * var(--vw, 1vw) * 15 / 16), -6px);
    }

    &__nav-item {
      &:has(.header__nav-children-list) {
        position: relative;

        &:hover {
          .header {
            &__nav-children-list-wrap {
              opacity: 1;
              pointer-events: all;
            }
          }
        }
      }
    }

    .link-button__text {
      display: block;
    }

    &__nav-children-list-wrap {
      position: absolute;
      top: 100%;
      background: var(--base-white-100);
      transition: opacity .3s;
      box-shadow: 0 2px 10px 0 #00000040;
      opacity: 0;
      pointer-events: none;
      z-index: 500;
    }

    &__nav-children-list {
      display: flex;
      flex-direction: column;
      background: white;
      padding-top: 16px;
      padding-bottom: 16px;
    }

    &__nav-child {
      background: white;
      padding: 6px 22px;
      display: flex;
      flex-wrap: nowrap;
      text-wrap: nowrap;

      font-family: var(--font);
      font-size: 15px;
      font-weight: 400;
      line-height: 145%;
    }
  }
}

@include respond('s-large') {
  .header {
    &__button-wrap {
      margin-left: 20px;
    }

    &__nav-list {
      padding-left: 0;
    }

    &__nav-link {
      padding: 0 clamp(6px, calc(15 * var(--vw, 1vw) / 16), 15px);
    }
  }
}

@include respond-down('s-large') {
  .header {
    &__version-text {
      display: none;
    }
  }
}

@include respond-up('medium') {
  .header {
    &__version {
      background: #E8EEF6;
      padding: 9px 14px;
      border-radius: 50px;
      height: 40px;
      display: flex;
      align-items: center;
    }

    &__phone {
      padding: 10px;
      margin: -10px;
    }
  }
}

@include respond('medium') {
  .header {
    &__phone {
      font-size: 14px;
    }

    &__wrap {
      height: 62px;
    }

    &__logo {
      svg {
        width: 127px;
        height: auto;
      }

      margin-left: 43px;
    }

    &__button-wrap {
      margin-left: 18px;
    }

    &__version {
      position: absolute;
      left: calc(255px + var(--grid-spacer));
      padding: 4px 18px;
    }
  }
}

@include respond-down('medium') {
  .header {
    &__container {
      position: relative;
      z-index: 4600;
      background: #fff;
    }

    &__part {
      &:nth-child(2) {
        flex: 1 0 auto;
      }
    }

    &__external-links {
      display: none;
    }

    &__nav-list-wrapper {
      display: none;
    }

    &__logo {
      display: flex;
      justify-content: center;
    }

    &__wrap_lower {
      display: none;
    }
  }
}

@include respond-down('small') {
  .header {
    &__button-wrap {
      svg {
        height: 18px;
        width: 18px;
      }
    }

    &__version {
      border-radius: 50%;
      border: 1px solid var(--light-blue);
      align-items: center;
      display: flex;
      justify-content: center;
      height: 40px;
      width: 40px;
      position: absolute;
      right: calc(var(--grid-spacer) + 46px);

      svg {
        height: 24px;
        width: 24px;
      }
    }

    &__wrap {
      height: 58px;
    }

    &__logo {
      margin-left: 20px;

      svg {
        width: 101px;
        height: auto;
      }
    }

    &__phone {
      display: none;
    }

    &__button {
      &.button__header {
        .button__icon-wrap {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}