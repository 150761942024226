.weapons-modal {
  display: flex;

  &__picture{
    display: flex;
  }

  &__text {
    display: flex;
    flex-direction: column;
    width: var(--grid-column4);
  }
}

@include respond-up("large") {
  .weapons-modal {
    padding-left: 43px;
    padding-top: 60px;
    padding-bottom: 60px;
    width: var(--grid-column8);
  }
}

@include respond-down("large") {
  .weapons-modal {
  }
}

@include respond-up("s-large") {
  .weapons-modal {
    padding-right: calc(var(--grid-column1) + var(--grid-gap));

    &__image-wrap {
      padding-right: calc(2 * var(--grid-gap));
    }

    &__weapon-description {
      padding-top: 18px;
    }
  }
}

@include respond("s-large") {
  .weapons-modal {
    padding-left: calc(43 * var(--vw, 1vw) / 16);
    padding-top: calc(60 * var(--vw, 1vw) / 16);
    padding-bottom: calc(60 * var(--vw, 1vw) / 16);
    width: var(--grid-column10);
  }
}

@include respond-down("s-large") {
  .weapons-modal {
  }
}

@include respond-up("medium") {
  .weapons-modal {
  }
}

@include respond("medium") {
  .weapons-modal {
    padding-top: 50px;

    &__image-wrap {
      width: var(--grid-column2);
    }

    &__weapon-description {
      padding-top: 15px;
      margin-bottom: 50px;
    }
  }
}

@include respond-down("medium") {
  .weapons-modal {
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__text {
      padding-top: 50px;
    }
  }
}

@include respond-down("small") {
  .weapons-modal {
    padding-top: 100px;

    &__image-wrap {
      width: calc(var(--grid-column2) + 2 * var(--grid-gap));
    }

    &__weapon-description {
      padding-top: 12px;
      margin-bottom: 100px;
    }
  }
}